<template>
  <!-- Login Banner -->
  <div class="col-lg-4 col-md-6 login-bg">
    <div class="owl-carousel login-slide owl-theme">
      <div class="welcome-login register-step">
        <div class="login-banner">
          <img src="@/assets/img/register-img.png" class="img-fluid" alt="Logo" />
        </div>
        <Carousel>
          <Slide v-for="slide in 3" :key="slide">
            <div class="mentor-course text-center">
              <h2>Bem vindo ao<br />CADUCEUS.</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                veniam.
              </p>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
  <!-- /Login Banner -->
</template>
<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
  },
});
</script>
