<template>
    <student-header></student-header>
    <student-breadcrumb :title="title" :text="text" :text1="text1" />
    <!-- Course Content -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <!-- sidebar -->
          <student-sidebar></student-sidebar>
          <!-- /Sidebar -->

        <!-- Student Perfil -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details mb-0">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Meu Perfil</h3>
              </div>
              <div class="checkout-form personal-address">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Nome</h6>
                      <p>Ronald</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Sobrenome</h6>
                      <p>Richard</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>User Name</h6>
                      <p>studentdemo</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Email</h6>
                      <p>studentdemo@example.com</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Telefone</h6>
                      <p>90154-91036</p>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="contact-info mb-0">
                      <h6>Sobre</h6>
                      <p>
                        Hello! I'm Ronald Richard. I'm passionate about developing
                        innovative software solutions, analyzing classic literature. I
                        aspire to become a software developer, work as an editor. In my
                        free time, I enjoy coding, reading, hiking etc.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Student Perfil -->
      </div>
    </div>
    <!-- /Pricing Plan -->
    </div>

    <layouts1></layouts1>
</template>
<script>
export default {
  data() {
    return {
      title: "Meu Perfil",
      text: "Home",
      text1: "Meu Perfil",
    };
  },
};
</script>
