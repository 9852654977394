<template>
  <div class="col-xl-3 col-lg-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- <div class="settings-widget dash-profile">
        <div class="settings-menu">
          <div class="profile-bg">
            <div class="profile-img">
              <router-link to="/caduceus/profile"
                ><img :src="userData.profileImageUrl" alt="Img" v-if="userData.profileImageUrl"
                />
                <img src="@/assets/img/user/user-17.jpg" alt="Img" v-else
              /></router-link>
            </div>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4>
                <router-link to="/caduceus/profile"
                  >{{userData.fullName}}</router-link
                >
              </h4>
              <p>{{userData.role}}</p>
              <router-link v-if="isInstructor || isAdmin"
                to="/caduceus/courses/add"
                class="add-course btn-primary"
                >Novo Curso</router-link
              >
            </div>
          </div>
        </div>
      </div> -->
      <div class="settings-widget account-settings">
        <div class="settings-menu">
          <h3 v-if="isAdmin">Administrador</h3>
          <ul v-if="isAdmin">
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/course') }"
            >
              <router-link
                to="/caduceus/dashboard"
                class="nav-link"
              >
                <i class="bx bxs-user"></i>Cursos
              </router-link>
            </li>
          </ul>
          <h3>Dashboard</h3>
          <ul>
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/dashboard') }"
            >
              <router-link
                to="/caduceus/dashboard"
                class="nav-link"
              >
                <i class="bx bxs-tachometer"></i>Dashboard
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/profile') }"
            >
              <router-link to="/caduceus/profile" class="nav-link">
                <i class="bx bxs-user"></i>Meu Perfil
              </router-link>
            </li>
            <!-- <li
              class="nav-item"
              :class="{
                active: isActive('/caduceus/enrolled-course'),
              }"
            >
              <router-link
                to="/caduceus/enrolled-course"
                class="nav-link"
              >
                <i class="bx bxs-graduation"></i>Meus Cursos
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/wishlist') }"
            >
              <router-link
                to="/caduceus/wishlist"
                class="nav-link"
              >
                <i class="bx bxs-heart"></i>Wishlist
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/reviews') }"
            >
              <router-link to="/caduceus/reviews" class="nav-link">
                <i class="bx bxs-star"></i>Reviews
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/quiz') }"
            >
              <router-link to="/caduceus/quiz" class="nav-link">
                <i class="bx bxs-shapes"></i>My Quiz Attempts
              </router-link>
            </li> -->
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/orders') }"
            >
              <router-link to="/caduceus/orders" class="nav-link">
                <i class="bx bxs-cart"></i>Meus Pagamentos
              </router-link>
            </li>

            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/subscriptions') }"
            >
              <router-link to="/caduceus/subscriptions" class="nav-link">
                <i class="bx bxs-cart"></i>Minhas Assinaturas
              </router-link>
            </li>
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/qa') }"
            >
              <router-link to="/caduceus/qa" class="nav-link">
                <i class="bx bxs-bookmark-alt"></i>Question & Answer
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/referral') }"
            >
              <router-link
                to="/caduceus/referral"
                class="nav-link"
              >
                <i class="bx bxs-user-plus"></i>Referrals
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/chat') }"
            >
              <router-link to="/caduceus/chat" class="nav-link">
                <i class="bx bxs-chat"></i>Messages
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{
                active: isActive('/caduceus/notifications'),
              }"
            >
              <router-link
                to="/caduceus/notifications"
                class="nav-link"
              >
                <i class="bx bxs-bell"></i>Notificações
              </router-link>
            </li> -->
            <!-- <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/tickets') }"
            >
              <router-link to="/caduceus/tickets" class="nav-link">
                <i class="bx bxs-coupon"></i>Tickets de Suporte
              </router-link>
            </li> -->
          </ul>
          <h3 v-if="isInstructor">Instructor</h3>
          <ul v-if="isInstructor">
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/course') }"
            >
              <router-link to="/caduceus/course" class="nav-link">
                <i class="bx bxs-rocket"></i>Meus Cursos
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('/caduceus/announcements'),
              }"
            >
              <router-link
                to="/caduceus/announcements"
                class="nav-link"
              >
                <i class="bx bxs-volume-full"></i>Announcementsss
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/withdraw') }"
            >
              <router-link
                to="/caduceus/withdraw"
                class="nav-link"
              >
                <i class="bx bxs-wallet"></i>Withdrawls
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('/caduceus/quiz-attempts'),
              }"
            >
              <router-link
                to="/caduceus/quiz-attempts"
                class="nav-link"
              >
                <i class="bx bxs-shapes"></i>Quiz Attempts
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/assignment') }"
            >
              <router-link
                to="/caduceus/assignment"
                class="nav-link"
              >
                <i class="bx bxs-file"></i>Assignments
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/earnings') }"
            >
              <router-link
                to="/caduceus/earnings"
                class="nav-link"
              >
                <i class="bx bxs-badge-dollar"></i>Earnings
              </router-link>
            </li>
          </ul>
          <h3>Configurações da Conta</h3>
          <ul>
            <li
              class="nav-item"
              :class="{ active: isActive('/caduceus/settings') }"
            >
              <router-link
                to="/caduceus/settings"
                class="nav-link"
              >
                <i class="bx bxs-cog"></i>Configurações
              </router-link>
            </li>
            <li class="nav-item">
              <a @click="logout" class="nav-link" style="cursor: pointer;">
                <i class="bx bxs-log-out"></i>Sair
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { JWTHandler } from '@/jwtHandler';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

// Variáveis reativas
const activeClass = ref("active");
const Available = ref(["I am Available Now", "Not Available"]);
const userData = ref(JWTHandler.getUserData());
const isAdmin = ref(JWTHandler.isCurrentTokenValid() && JWTHandler.getUserData().isRole('ADMIN'));
const isInstructor = ref(JWTHandler.isCurrentTokenValid() && JWTHandler.getUserData().isRole('INSTRUCTOR'));
const isStudent = ref(JWTHandler.isCurrentTokenValid() && JWTHandler.getUserData().isRole('STUDENT'));

// Composição de roteamento
const route = useRoute();
const router = useRouter();
const logout = () => {
  JWTHandler.logout();
  router.push('/')

};

// Método isActive
const isActive = (paths) => {
  return paths.includes(route.path);
};
</script>

