<template>
  <!-- <layouts-instructorborder></layouts-instructorborder> -->
  <div>
    <course-layout-breadcrumb :title="course?.title" :student="studentName" />

    <!-- Page Content -->
    <div class="page-content">
      <div class="container" style="max-width: 100%;">
        <div class="row">

          <!-- sidebar -->
          <div class="col-xl-3 col-lg-3 theiaStickySidebar">
            <div class="stickysidebar">
              <!-- <div class="settings-widget dash-profile">
        <div class="settings-menu">
          <div class="profile-bg">
            <div class="profile-img">
              <router-link to="/caduceus/profile"
                ><img :src="userData.profileImageUrl" alt="Img" v-if="userData.profileImageUrl"
                />
                <img src="@/assets/img/user/user-17.jpg" alt="Img" v-else
              /></router-link>
            </div>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4>
                <router-link to="/caduceus/profile"
                  >{{userData.fullName}}</router-link
                >
              </h4>
              <p>{{userData.role}}</p>
              <router-link v-if="isInstructor || isAdmin"
                to="/caduceus/courses/add"
                class="add-course btn-primary"
                >Novo Curso</router-link
              >
            </div>
          </div>
        </div>
      </div> -->

              <div class="settings-widget account-settings">
                <div class="settings-menu">
                  <Loading v-model:active="loadingStore.isLoading" :can-cancel="false" :is-full-page="true" />
                  <div class="logos" v-if="course">
                    <img src="@/assets/img/caduceus.png" alt="LMS Logo">
                    <router-link to="/caduceus/dashboard" class="btn btn-enroll w-100" style="margin-top: 20px;">Meus
                      Cursos</router-link>
                  </div>

                  <template v-for="(module, index) in course?.modules" :key="module.id">
                    <h5 style="padding-bottom: 12px;"> {{ module.title }}</h5>
                    <ul>
                      <li v-for="(lesson, index2) in module.lessons" :key="lesson.id"
                        @click="selectLesson(module, lesson)" class="nav-item">
                        <span v-bind:class="{ active: currentLesson.title === lesson.title }">
                          <i class="fa fa-play-circle" v-if="lesson.contentType === 'YOUTUBE'"></i>
                          <i class="fa fa-file-pdf" v-if="lesson.contentType === 'PDF'"></i> {{ lesson.title }}

                        </span>
                      </li>
                    </ul>
                  </template>

                  <h5 style="padding-bottom: 12px;" v-if="isCertificateAvailable"> CERTIFICADO</h5>
                  <ul v-if="isCertificateAvailable">
                    <li @click="selectCertificate()" class="nav-item">
                      <span v-bind:class="{ active: selectedContent === 'certificate' }">
                        <i class="fa fa-trophy "></i> Meu Certificado
                      </span>
                    </li>
                  </ul>



                </div>
              </div>
            </div>
          </div>
          <!-- /Sidebar -->

          <!-- Student Dashboard -->
          <div class="col-xl-9 col-lg-9">


            <!-- /Dashboard Grid -->



            <div class="dashboard-title">
              <h4>{{ currentLesson.title }}</h4>
            </div>
            <div class="row">

              <!-- Course Grid -->
              <div class="col-xxl-12 col-md-12 d-flex">
                <div class="course-box flex-fill">
                  <div v-show="selectedContent === 'lesson'" class="video-wrapper">
                    <div class="video-player">
                      <LiteYouTubeEmbed v-if="currentLesson.contentType === 'YOUTUBE'"
                        :id="currentLesson.contentUrl.split('=')[1]" class="video-embed" title="Vídeo da Lição" />
                        <embed v-if="currentLesson.contentType === 'PDF'" :src="'https://docs.google.com/gview?embedded=true&url=' + currentLesson.contentUrl" type="application/pdf" width="100%" height="600px" />
                      
                      <!-- <iframe v-if="currentLesson.contentType === 'PDF'" :src="currentLesson.contentUrl" frameborder="0"></iframe>
                        <a :href="currentLesson.contentUrl" download v-if="currentLesson.contentUrl">Baixar</a> -->

                    </div>
                  </div>

                  <!-- 🔹 Área do certificado -->
                  <div v-if="selectedContent === 'certificate'" class="certificate-container">
                    <iframe v-if="certificateUrl" :src="certificateUrl" frameborder="0"></iframe>
                    <a :href="certificateUrl" download v-if="certificateUrl">Baixar Certificado</a>
                  </div>

                  <!-- <div class="product">
                  <div class="product-content">
                  </div>
                </div> -->
                </div>
              </div>
              <!-- /Course Grid -->

              <!-- Course Grid -->
              <!-- <div class="col-xxl-4 col-md-6 d-flex">
                          <div class="course-box flex-fill">
                              <div class="product">
                                  <div class="product-img">
                                      <router-link to="/course/course-details">
                                          <img class="img-fluid" alt="Img" src="@/assets/img/course/course-03.jpg">
                                      </router-link>
                                      <div class="price combo">
                                          <h3>FREE</h3>
                                      </div>
                                  </div>
                                  <div class="product-content">
                                      <div class="course-group d-flex">
                                          <div class="course-group-img d-flex">
                                              <router-link to="/instructor/instructor-profile"><img src="@/assets/img/user/user5.jpg"
                                                      alt="Img" class="img-fluid"></router-link>
                                              <div class="course-name">
                                                  <h4><router-link to="/instructor/instructor-profile">Jenny</router-link></h4>
                                                  <p>Instructor</p>
                                              </div>
                                          </div>
                                          <div class="course-share d-flex align-items-center justify-content-center">
                                              <a href="#"><i class="fa-regular fa-heart"></i></a>
                                          </div>
                                      </div>
                                      <h3 class="title instructor-text"><router-link to="/course/course-details">Sketch from A to
                                              Z (2022): Become an app designer</router-link></h3>
                                      <div class="course-info d-flex align-items-center">
                                          <div class="rating-img d-flex align-items-center">
                                              <img src="@/assets/img/icon/icon-01.svg" alt="Img">
                                              <p>10+ Lesson</p>
                                          </div>
                                          <div class="course-view d-flex align-items-center">
                                              <img src="@/assets/img/icon/icon-02.svg" alt="Img">
                                              <p>40hr 10min</p>
                                          </div>
                                      </div>
                                      <div class="rating mb-0">
                                          <i class="fas fa-star filled me-1"></i>
                                          <i class="fas fa-star filled me-1"></i>
                                          <i class="fas fa-star filled me-1"></i>
                                          <i class="fas fa-star me-1"></i>
                                          <i class="fas fa-star me-1"></i>
                                          <span class="d-inline-block average-rating"><span>3.0</span> (18)</span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> -->
              <!-- /Course Grid -->


            </div>
            <!-- <div class="dash-pagination">
                      <div class="row align-items-center">
                          <div class="col-6">
                              <p>Page 1 of 2</p>
                          </div>
                          <div class="col-6">
                              <ul class="pagination">
                                  <li class="active">
                                      <a href="#">1</a>
                                  </li>
                                  <li>
                                      <a href="#">2</a>
                                  </li>
                                  <li>
                                      <a href="#"><i class="bx bx-chevron-right"></i></a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div> -->
          </div>
          <!-- Student Dashboard -->

        </div>
      </div>
    </div>
    <!-- /Page Content -->
  </div>
  <!-- <layouts1></layouts1> -->
</template>


<script setup>
import ApiService from '@/services/ApiService';
import axiosInstance, { tratarError } from '@/axiosConfig';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import { storeToRefs } from 'pinia';
import { useLoadinStore } from '@/stores/loadingStore';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { BCard, BDropdown, BDropdownItem } from 'bootstrap-vue-3';
const loadingStore = storeToRefs(useLoadinStore());
const title = ref('title')
const text = ref('text')
const text1 = ref('text1')
// Dados simulados para exemplo
const studentName = ref('');
const courseTitle = 'Curso de Vue.js';
const route = useRoute();
const slug = route.params.slug; // ou route.params.slug, dependendo do seu setup
const course = ref();
const isCertificateAvailable = ref(false);
onMounted(async () => {

  // Carregar dados do curso e do aluno
  try {
    const response = await ApiService.get(`/enrollments/${slug}/course`);
    course.value = response.data.course;
    studentName.value = response.data.student;
    isCertificateAvailable.value = response.data.isCertificateAvailable;
    selectLesson(course.value.modules[0], course.value.modules[0].lessons[0]);


    if (!window.YT) {
      const scriptTag = document.createElement('script');
      scriptTag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(scriptTag);
      window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
    } else {
      initializePlayer(); // Caso o script já esteja carregado
    }
  } catch (error) {
    tratarError(error);
    console.error('Erro no pagamento:', error);
  }
  // studentName.value = response.data.user.firstName;

});
// const modules = ref([
//   { id: 1, title: 'Módulo 1', lessons: [
//       { id: 101, title: 'Lição 1.1', contentType: 'VIDEO', contentUrl: 'https://path.to/video1' },
//       { id: 102, title: 'Lição 1.2', contentType: 'VIDEO', contentUrl: 'https://path.to/video2' }
//     ]},
//   { id: 2, title: 'Módulo 2', lessons: [
//       { id: 201, title: 'Lição 2.1', contentType: 'PDF', contentUrl: 'https://path.to/doc1.pdf' },
//       { id: 202, title: 'Lição 2.2', contentType: 'VIDEO', contentUrl: 'https://path.to/video3' }
//     ]}
// ]);

// Estado reativo para a lição selecionada
const currentLesson = ref({});
const currentModule = ref({});
const selectedContent = ref("lesson");
const certificateUrl = ref();
const selectLesson = (module, lesson) => {
  currentLesson.value = lesson;
  currentModule.value = module;
  selectedContent.value = 'lesson';
  showPlayer.value = true;

  if (currentLesson.value.contentType === 'YOUTUBE') {
    if (player) {

      // player.loadVideoById(currentLesson.value.contentUrl.split('=')[1]);
    }

  }
};

const selectCertificate = async () => {
  showPlayer.value = false;
  try {
    const response = await ApiService.get(`/enrollments/certificate/${slug}`);
    certificateUrl.value = response.data;
  } catch (error) {
    tratarError(error);
    console.error('Erro no pagamento:', error);
  }
  selectedContent.value = 'certificate';
};

const showPlayer = ref(true); // Controla o v-if

let player;
let updateInterval;
const watchedTime = ref(0);
function initializePlayer() {
  // Verifica se a API está disponível
  if (window.YT && window.YT.Player) {
    player = new YT.Player('player', {
      height: '360',
      width: '640',
      videoId: 'dQw4w9WgXcQ', // Video inicial
      events: {
        'onReady': onPlayerReady,
        'onStateChange': onPlayerStateChange
      }
    });
  }
}
// Função a ser chamada quando a API estiver pronta
function onYouTubeIframeAPIReady() {
  console.log('YouTube API Ready');
  initializePlayer();
}

// Função chamada quando o player está pronto
function onPlayerReady(event) {
  console.log('Player is readys');
  event.target.playVideo();
}

// Manipulador para mudanças no estado do player

function onPlayerStateChange(event) {
  if (event.data === YT.PlayerState.PLAYING) {
    updateInterval = setInterval(() => {
      watchedTime.value = Math.floor(player.getCurrentTime());
    }, 1000);
  } else {
    clearInterval(updateInterval);
  }
}
// Reconstrua o player sempre que showPlayer mudar para true
watch(showPlayer, (newValue) => {
  if (newValue) {
    initializePlayer();
  } else {
    clearInterval(updateInterval);
  }
});
function togglePlayer() {
  showPlayer.value = !showPlayer.value;
}
onBeforeUnmount(() => {
  clearInterval(updateInterval);
});
</script>

<style>
.nav-item:hover {
  color: #129f46;
  font-weight: bold;
  cursor: pointer;
}

.active {
  color: #129f46
}

.certificate-container {
  padding: 20px;
  background-color: #f9f9f9;
  /* Cor de fundo opcional */
  text-align: center;
  /* Centraliza o conteúdo */
}

.certificate-container iframe {
  border: none;
  width: 100%;
  min-height: fit-content;
  min-height: 600px;
}

/* 🔹 Wrapper do vídeo para garantir altura correta */
.logos {
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.logos img {
  max-width: 100%;
  height: auto;
}
</style>