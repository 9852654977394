<template>
  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>Ticket ID</th>
          <th>Date</th>
          <th>Subject</th>
          <th>Priority</th>
          <th>Category</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ticket#003</td>
          <td>March 27, 2024</td>
          <td>Account Activation mail not received</td>
          <td>
            <span class="resut-badge badge-light-danger">High</span>
          </td>
          <td>Mailing Issues</td>
          <td>
            <span class="status-badge badge-soft-danger">Closed</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#005</td>
          <td>April 24, 2024</td>
          <td>Payment Processed but not showed</td>
          <td>
            <span class="resut-badge badge-light-success">Low</span>
          </td>
          <td>Payment Issues</td>
          <td>
            <span class="status-badge badge-soft-danger">Closed</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
