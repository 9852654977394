import { useLoading } from 'vue-loading-overlay';

export default {
  install(app) {
    const $loading = useLoading({
      height: 255,
      width: 255,
      canCancel: false,
      color: "#00AB00",
      backgroundColor: "#4B4B4B",
    });

    // Adiciona $loading como uma propriedade global
    app.config.globalProperties.$loading = $loading;
  },
};