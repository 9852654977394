<template>
  <div class="tab-content">
    <!-- All -->
    <div class="tab-pane fade active show" :id="'alltab_' + category_id" role="tabpanel">
      <div class="all-course">
        <div class="row" v-if="courses">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up" v-for="course in getCourses()" :key="course.id">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link :to="course.slug">
                    <img v-if="!course.coverImage"
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                    <img v-else
                      class="img-fluid"
                      alt=""
                      :src="course.coverImage"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link :to="course.slug"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" v-if="!course.instructor.profileImageUrl"
                      /><img :src="course.instructor.profileImageUrl" alt="" class="img-fluid" v-else
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link :to="course.slug">
                      <p>{{course.category.description}}</p>
                      <h3 class="title instructor-text">
                        {{course.title}}
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>{{course.enrolledStudentsCount}} aluno(s)</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three" v-if="!course.isPublic">
                        <h3>R$ {{ course.basePrice }} <span>R$ {{ course.basePrice * 1.5 }}</span></h3>
                      </div>
                      <div class="course-price-three" v-else>
                        <h3>R$ 0,00 <span>R$ {{ course.basePrice }}</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-pen-to-square me-2"></i>
                      <span>{{course.lessonsCount}} aulas</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

        </div>
      </div>
    </div>
    <!-- /All -->

    <!-- Most popular -->
    <div class="tab-pane fade" :id="'category_is_public_' + category_id">
      <div class="all-course">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up" v-for="course in getCourses().filter(c => c.isPublic)" :key="course.id">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link :to="course.slug">
                    <img v-if="!course.coverImage"
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                    <img v-else
                      class="img-fluid"
                      alt=""
                      :src="course.coverImage"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link :to="course.slug"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" v-if="!course.instructor.profileImageUrl"
                      /><img :src="course.instructor.profileImageUrl" alt="" class="img-fluid" v-else
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link :to="course.slug">
                      <p>{{course.category.description}}</p>
                      <h3 class="title instructor-text">
                        {{course.title}}
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>{{course.enrolledStudentsCount}} aluno(s)</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three" v-if="!course.isPublic">
                        <h3>R$ {{ course.basePrice }} <span>R$ {{ course.basePrice * 1.5 }}</span></h3>
                      </div>
                      <div class="course-price-three" v-else>
                        <h3>R$ 0,00 <span>R$ {{ course.basePrice }}</span></h3>
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-pen-to-square me-2"></i>
                      <span>{{course.lessonsCount}} aulas</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

        </div>
      </div>
    </div>

    <div class="tab-pane fade" :id="'category_freeToSubscribers_' + category_id">
      <div class="all-course">
        <div class="row">
          <!-- Col -->
          <div class="col-xl-3 col-lg-6 col-md-6 col-12" data-aos="fade-up" v-for="course in getCourses().filter(c => c.freeToSubscribers)" :key="course.id">
            <div class="course-box-three">
              <div class="course-three-item">
                <div class="course-three-img">
                  <router-link :to="course.slug">
                    <img v-if="!course.coverImage"
                      class="img-fluid"
                      alt=""
                      src="@/assets/img/course/course-26.jpg"
                    />
                    <img v-else
                      class="img-fluid"
                      alt=""
                      :src="course.coverImage"
                    />
                  </router-link>
                  <div class="heart-three">
                    <a href="javascript:;"><i class="fa-regular fa-heart"></i></a>
                  </div>
                </div>
                <div class="course-three-content">
                  <div class="course-group-three">
                    <div class="group-three-img">
                      <router-link :to="course.slug"
                        ><img src="@/assets/img/user/user1.jpg" alt="" class="img-fluid" v-if="!course.instructor.profileImageUrl"
                      /><img :src="course.instructor.profileImageUrl" alt="" class="img-fluid" v-else
                      /></router-link>
                    </div>
                  </div>

                  <div class="course-three-text">
                    <router-link :to="course.slug">
                      <p>{{course.category.description}}</p>
                      <h3 class="title instructor-text">
                        {{course.title}}
                      </h3>
                    </router-link>
                  </div>

                  <div class="student-counts-info d-flex align-items-center">
                    <div class="students-three-counts d-flex align-items-center">
                      <img src="@/assets/img/icon-three/student.svg" alt="" />
                      <p>{{course.enrolledStudentsCount}} aluno(s)</p>
                    </div>
                  </div>

                  <div
                    class="price-three-group d-flex align-items-center justify-content-between justify-content-between"
                  >
                    <div class="price-three-view d-flex align-items-center">
                      <div class="course-price-three">
                        <small>Gratuito para Assinantes</small>
                        <h3>R$ {{ course.basePrice }} <span>R$ {{ course.basePrice * 1.5 }}</span></h3>
                        <!-- <h3>R$ 0,00 <span>R$ {{ course.basePrice * 1.5 }}</span></h3>
                        <h4>(assinantes)</h4> -->
                      </div>
                    </div>
                    <div class="price-three-time d-inline-flex align-items-center">
                      <i class="fa-regular fa-pen-to-square me-2"></i>
                      <span>{{course.lessonsCount}} aulas</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

        </div>
      </div>
    </div>
    <!-- /Most popular -->

    <!-- /Database -->
  </div>
</template>
<script setup>
import ApiService from '@/services/ApiService';
import axiosInstance from '@/axiosConfig';
import { onMounted, ref } from 'vue';
import { props } from 'vue3-tags-input';

const myProps = defineProps({
  courses: {
    type: Array,
    required: true
  },
  category_id: {
    type: Number,
    required: true
  },
  isEchoflix: {
    type: Boolean,
    required: false
  }

});

const getCourses = () => {
  if(+myProps.category_id === 5) {
    return [...myProps.courses].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }
  return myProps.courses
  
}
onMounted(async () => {

  
});



</script>

<style scoped>
.course-box-three:hover .course-three-content {
  background-color: green;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

.course-box-three-echoflix:hover .course-three-content-echoflix {
  background-color: black;
  -webkit-transition: 0.7s;
  -moz-transition: 0.7s;
  -o-transition: 0.7s;
  transition: 0.7s;
}

</style>