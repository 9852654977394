<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Configurações -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              
             <instructor-settings-sidebar></instructor-settings-sidebar>
              <div class="checkout-form settings-wrap">
                <h5>Choose when and how to be notified</h5>
                <ul class="settings-noti-lists">
                  <li>
                    <div class="notification-title">
                      <h6>Student Questions</h6>
                      <p>
                        Notify me when a student asks a question in the Q&A
                        section
                      </p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user1"
                        class="check"
                        checked=""
                      />
                      <label for="user1" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Feedback Received</h6>
                      <p>Notify me when receive feedback from students</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user2"
                        class="check"
                        checked=""
                      />
                      <label for="user2" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Quiz/Exam Results Submission</h6>
                      <p>Notify me when quiz or exam results are submitted</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user3"
                        class="check"
                        checked=""
                      />
                      <label for="user3" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Forum Activity</h6>
                      <p>
                        Notify me about new posts or replies in course
                        discussion forums.
                      </p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input type="checkbox" id="user4" class="check" />
                      <label for="user4" class="checktoggle"> </label>
                    </div>
                  </li>
                </ul>
                <h5>Email Notificações</h5>
                <ul class="settings-noti-lists email-noti-lists">
                  <li>
                    <div class="notification-title">
                      <h6>Course Enrollment</h6>
                      <p>
                        Send me emails when a new student enrolls the course
                      </p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user5"
                        class="check"
                        checked=""
                      />
                      <label for="user5" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Assignment Submission</h6>
                      <p>
                        Send me email whenever an assignment is submitted by a
                        student
                      </p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user6"
                        class="check"
                        checked=""
                      />
                      <label for="user6" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>System Atualizars</h6>
                      <p>Send me emails about updates to the LMS platform</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user7"
                        class="check"
                        checked=""
                      />
                      <label for="user7" class="checktoggle"> </label>
                    </div>
                  </li>
                </ul>
                <a href="#" class="btn btn-primary">Salvar Changes</a>
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Configurações -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Configurações",
      text: "Home",
      text1: "Notificações",
    };
  },
};
</script>
