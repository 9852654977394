import axios from 'axios';
import { ref } from 'vue';
import { useLoading } from 'vue-loading-overlay';
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import { useLoadinStore } from './stores/loadingStore';
// Criação de uma instância do Axios
const axiosInstance = axios.create({
  baseURL: '/api', // Configura a base URL para as requisições
  timeout: 30000, // Configura um timeout padrão (opcional)
});
const $toast = useToast();
// const loadinStore = useLoadinStore();
// Interceptor para adicionar o token JWT ao cabeçalho Authorization
axiosInstance.interceptors.request.use(
  (config) => {
    // loadinStore.show();
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // loadinStore.hide();
    return Promise.reject(error);
  }
);

// Interceptor para tratar respostas e erros
axiosInstance.interceptors.response.use(
  (response) => {
    // loadinStore.hide();    // Resposta normal, retorna sem modificar
    return response;
  },
  (error) => {
    // loadinStore.hide();
        console.log(error.response.status);
    // if (error.response && error.response.status === 422) {
    //   // Tratar erro de validação (422)
    //   const validationErrors = error.response.data;
    //   if (Array.isArray(validationErrors)) {
    //     const errorMessage = validationErrors[0]; // Junta os erros com quebra de linha
    //     $toast.error(errorMessage, {
    //       timeout: 8000,
    //       position: "top"
    //     });
    //   } else if(validationErrors.message) {
    //     $toast.error(validationErrors.message, {
    //       timeout: 8000,
    //       position: "top"
    //     });
    //   }
        
      
    //   // Retornar Promise.resolve para evitar o `catch`
    //   return Promise.resolve({ handledError: true });
    // }
    // Propagar outros erros para o `catch`
    return Promise.reject(error);
  }
);
export const tratarError = (error) => {
  if (error.response && error.response.status === 422) {
    // Tratar erro de validação (422)
    const validationErrors = error.response.data;
    if (Array.isArray(validationErrors)) {
      const errorMessage = validationErrors[0]; // Junta os erros com quebra de linha
      $toast.error(errorMessage, {
        timeout: 8000,
        position: "top"
      });
    } else if(validationErrors.message) {
      $toast.error(validationErrors.message, {
        timeout: 8000,
        position: "top"
      });
    }
      
    
    // Retornar Promise.resolve para evitar o `catch`
    return Promise.resolve({ handledError: true });
  } else if (error.response?.status === 403) {
    $toast.error(error.response.data.message, {
      timeout: 8000,
      position: "top"
    });
  }
}

export default axiosInstance;