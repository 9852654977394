<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Configurações -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <instructor-settings-sidebar></instructor-settings-sidebar>
              <div class="checkout-form settings-wrap">
                <div class="edit-profile-info">
                  <h5>Selecione a forma de pagamento</h5>
                </div>
                <div class="del-acc">
                  <h6>Warning</h6>
                  <p>
                    If you close your account, you will be unsubscribed from all
                    your 0 courses, and will lose access forever.
                  </p>
                </div>
                <router-link to="/home/" class="btn btn-primary"
                  >Delete My Account</router-link
                >
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Configurações -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Configurações",
      text: "Home",
      text1: "Delete Account",
    };
  },
};
</script>
