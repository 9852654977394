<template>
        <Loading
            v-model:active="loadingStore.isLoading"
            :can-cancel="false"
            :is-full-page="true"
          />

  <router-view></router-view>
</template>
<script setup>
import { useLoadinStore } from '@/stores/loadingStore';
import { storeToRefs } from 'pinia';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";


const loadingStore = storeToRefs(useLoadinStore());
</script>