<template>
  <div class="row">
    

    <div class="col-md-6 login-wrap-bg">
      <!-- Login -->
      <div class="login-wrapper">
        <div class="loginbox">
          <div class="w-100">
            <div class="img-logo">
              <img src="@/assets/img/caduceus.png" class="img-fluid" alt="Logo" />
              <div class="back-home">
                <router-link to="/home/">Voltar para Home</router-link>
              </div>
            </div>
            <h1>Entrar com sua conta</h1>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
              <div class="input-block">
                <label class="form-control-label">Email</label>
                <div class="form-addons">
                  <Field
                    name="email"
                    type="text"
                    value=""
                    class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                </div>
              </div>
              <div class="input-block">
                <label class="form-control-label">Password</label>
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    value=""
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <span @click="toggleShow" class="toggle-password"
                  :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  >
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
              </div>
              <div class="forgot">
                <span
                  ><a class="forgot-link" href="forgot-password"
                    >Esqueceu o Password ?</a
                  ></span
                >
              </div>
<!--              <div class="remember-me">
                <label class="custom_check mr-2 mb-0 d-inline-flex remember-me">
                  Remember me
                  <input type="checkbox" name="radio" />
                  <span class="checkmark"></span>
                </label>
              </div>-->
              <div class="d-grid">
                <input class="btn btn-primary btn-start" type="submit" value="Entrar"> 
              </div>
            </Form>
          </div>
        </div>
        <div class="google-bg text-center" v-if="false">
          <span><a href="#">Or sign in with</a></span>
          <div class="sign-google">
            <ul>
              <li>
                <a href="#"
                  ><img
                    src="@/assets/img/net-icon-01.png"
                    class="img-fluid"
                    alt="Logo"
                  />
                  Sign In using Google</a
                >
              </li>
              <li>
                <a href="#"
                  ><img
                    src="@/assets/img/net-icon-02.png"
                    class="img-fluid"
                    alt="Logo"
                  />Sign In using Facebook</a
                >
              </li>
            </ul>
          </div>
          <p class="mb-0">
            New User ? <router-link to="register">Create an Account</router-link>
          </p>
        </div>
      </div>
      <!-- /Login -->
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useRouter, useRoute } from "vue-router"; // Use useRouter e useRoute adequadamente
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ApiService from '@/services/ApiService';
import axiosInstance from "@/axiosConfig";
import { JWTHandler } from "@/jwtHandler";

const $toast = useToast();
const router = useRouter(); 
const route = useRoute(); // Certifique-se que isso está no escopo correto

const showPassword = ref(false);
const emailError = ref("");
const passwordError = ref("");

const buttonLabel = computed(() => showPassword.value ? "Hide" : "Show");

const toggleShow = () => {
  showPassword.value = !showPassword.value;
};

// Schema de validação
const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
});

const onSubmit = async (values) => {
  try {
    const response = await ApiService.post("auth/signin", values);

    const redirect = route.query.redirect; // Captura o query param course desde que esteja correto
    const data = response.data; // Supondo que o token seja retornado no campo `token`
    console.log('token')
    if (data.token) {
      // Armazena o token no localStorage
      localStorage.setItem('jwtToken', data.token);
      $toast.success('Login realizado com sucesso!', {
        timeout: 8000,
        position: "top"
      });
      
      // Faz o redirecionamento com base nos parâmetros
      if (redirect) {
        window.location.href = redirect;
      //   router.push({
      //     path: 
      // }); // Ajuste se o caminho for diferente
      } else {
        if(JWTHandler.hasRole('INSTRUCTOR') || JWTHandler.hasRole('ADMIN')) {
          router.push("/caduceus/course");
        } else {
          router.push("/caduceus/dashboard");
        }
        
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      $toast.error("Invalid email or password", {
        position: "top"
      });
    } else {
      console.error("An unexpected error occurred", error);
    }
  }
};

// Assegure-se de retornar tudo que é necessário para uso no template
</script>