<template>
    <layouts-instructorborder></layouts-instructorborder>

    <student-breadcrumb :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="page-content">
        <div class="container">
            <div class="row">

                <!-- sidebar -->
                <instructor-sidebar></instructor-sidebar>
                <!-- /Sidebar -->

                <!-- Instructor profile -->
                <div class="col-xl-9 col-lg-9">

                    <div class="settings-widget card-details mb-0">
                        <div class="settings-menu p-0">
                            <div class="profile-heading">
                                <h3>Meu Perfil</h3>
                            </div>
                            <div class="checkout-form personal-address">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="contact-info">
                                            <h6>Nome</h6>
                                            <p>{{userData?.fullName}}</p>
                                        </div>
                                    </div>
                                   
                                    <div class="col-sm-6">
                                        <div class="contact-info">
                                            <h6>Registerion Date</h6>
                                            <p>{{userData?.createdAt}}</p>
                                        </div>
                                    </div>
                                   
                                    <div class="col-sm-6">
                                        <div class="contact-info">
                                            <h6>Email</h6>
                                            <p>{{userData?.email}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="contact-info">
                                            <h6>Telefone</h6>
                                            <p>{{userData?.mobilePhone}}</p>
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="contact-info mb-0">
                                            <h6>Sobre</h6>
                                            <p v-html="userData?.bio"></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- /Instructor profile -->

            </div>
        </div>
    </div>
    <!-- /Page Content -->

    <layouts1></layouts1>
</template>

<script setup>
import { ref, onMounted } from "vue";
import ApiService from '@/services/ApiService';
import axiosInstance, { tratarError } from "@/axiosConfig";

// Variáveis reativas
const title = ref("Meu Perfil");
const text = ref("Home");
const text1 = ref("Meu Perfil");
const userData = ref(null); // Variável para armazenar os dados do usuário

// Função para buscar os dados do usuário
const fetchUserData = async () => {
  try {
    const response = await ApiService.get("/users/me");
    userData.value = response.data;
  } catch (error) {
    tratarError(error);

    console.error("Erro ao buscar os dados do usuário:", error);
  }
};

// Chamar a função quando o componente for montado
onMounted(() => {
  fetchUserData();
});
</script>