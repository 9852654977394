<template>
  <div class="settings-page-head">
    <ul class="settings-pg-links">
      <li>
        <router-link to="/student/student-settings"
          ><i class="bx bx-edit"></i>Editar Perfil</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-change-password"
          ><i class="bx bx-lock"></i>Mudar Password</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-social-profile"
          ><i class="bx bx-user-circle"></i>Social Perfils</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-linked-accounts"
          ><i class="bx bx-link"></i>Linked Accounts</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-notifications"
          ><i class="bx bx-bell"></i>Notifications</router-link
        >
      </li>
    </ul>
  </div>
</template>
