<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Configurações -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              
              <instructor-settings-sidebar></instructor-settings-sidebar>
              <form action="/instructor-setting-withdraw">
                <div class="checkout-form settings-wrap pb-0">
                  <div class="edit-profile-info">
                    <h5>Selecione a forma de pagamento</h5>
                  </div>
                  <ul
                    class="nav nav-pills d-block"
                    id="pills-tab1"
                    role="tablist"
                  >
                    <li class="wallet-method withdraw-box" role="presentation">
                      <label
                        class="custom_radio mb-0"
                        id="pills-public-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-public"
                        role="tab"
                        aria-controls="pills-public"
                        aria-selected="true"
                      >
                        <input type="radio" name="public" />
                        <span class="checkmark"></span> Bank Transfer</label
                      >
                      <p>Minimum withdraw $80</p>
                    </li>
                    <li class="wallet-method withdraw-box" role="presentation">
                      <label
                        class="custom_radio mb-0"
                        id="pills-private-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-private"
                        role="tab"
                        aria-controls="pills-private"
                        aria-selected="false"
                      >
                        <input type="radio" name="public" />
                        <span class="checkmark"></span> E-Check</label
                      >
                      <p>Minimum withdraw $80</p>
                    </li>
                    <li class="wallet-method withdraw-box" role="presentation">
                      <label
                        class="custom_radio mb-0 active"
                        id="pills-select-people-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-select-people"
                        role="tab"
                        aria-controls="pills-select-people"
                        aria-selected="false"
                      >
                        <input type="radio" name="public" checked />
                        <span class="checkmark"></span> PayPal</label
                      >
                      <p>Minimum withdraw $80</p>
                    </li>
                  </ul>
                  <div class="wrap-wallet">
                    <div class="tab-content" id="pills-tabContent">
                      <div
                        class="tab-pane fade"
                        id="pills-public"
                        role="tabpanel"
                        aria-labelledby="pills-public-tab"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-block">
                              <label class="form-label">Account Name</label>
                              <input type="text" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="input-block">
                              <label class="form-label">Account Number</label>
                              <input type="text" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="input-block">
                              <label class="form-label">Bank Name</label>
                              <input type="text" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="input-block">
                              <label class="form-label">IBAN</label>
                              <input type="text" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="input-block">
                              <label class="form-label">BIC / SWIFT</label>
                              <input type="text" class="form-control" />
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button class="btn btn-primary" type="submit">
                              Salvar Withdrawal Account
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade"
                        id="pills-private"
                        role="tabpanel"
                        aria-labelledby="pills-private-tab"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-block">
                              <label class="form-label"
                                >Your Physical Address</label
                              >
                              <input type="text" class="form-control" />
                              <p class="label-slug">
                                We will send you an E-Check to this address
                                directly
                              </p>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button class="btn btn-primary" type="submit">
                              Salvar Withdrawal Account
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        class="tab-pane fade show active"
                        id="pills-select-people"
                        role="tabpanel"
                        aria-labelledby="pills-select-people-tab"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <div class="input-block">
                              <input
                                type="text"
                                class="form-control"
                                value="test@example.com"
                              />
                              <p class="label-slug">
                                We will use this email address to send the money
                                to your Paypal account
                              </p>
                              <label class="form-label"
                                >PayPal Email Address</label
                              >
                            </div>
                          </div>
                          <div class="col-md-12">
                            <button class="btn btn-primary" type="submit">
                              Salvar Withdrawal Account
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Instructor Configurações -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Configurações",
      text: "Home",
      text1: "Withdraw",
    };
  },
};
</script>
