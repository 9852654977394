import axios from "axios";
import VueAxios from "vue-axios";
import Swal from "sweetalert2";
import { useLoadinStore } from "@/stores/loadingStore";

/**
 * @description serviço para fazer requisições HTTP com Axios
 */
class ApiService {
  /**
   * @description propriedade para compartilhar a instância do Vue
   */
  static vueInstance;

  /**
   * @description inicializa o Vue com Axios
   */
  static init(app) {
    const loadingStore = useLoadinStore();
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = "/api";

    // Interceptor de respostas
    ApiService.vueInstance.axios.interceptors.response.use(
      (response) => {
        loadingStore.hide(); // Esconde o loading
        return response;
      },
      (error) => {
        loadingStore.hide(); // Esconde o loading
        // Tratamento de erros baseado no status HTTP
        switch (error.response?.status) {
          case 500:
            Swal.fire({
              text: "Ocorreu um erro ao processar sua requisição. Entre em contato com o administrador.",
              icon: "error",
              confirmButtonText: "Ok",
            });
            break;
          case 422:
            let finalMessage = error.response?.data?.message || '';
            if(error.response?.data?.details) {
              if(Array.isArray(error.response?.data?.details)) {
                error.response?.data?.details.map(e => finalMessage+= e.field + '.<br/>')
              } 
            }
            Swal.fire({
              html: finalMessage || "Erro de validação.",
              // text: ,
              icon: "warning",
              confirmButtonText: "Ok",
            });
            break;
          case 401:
            Swal.fire({
              text: error.response?.data?.message || "Não autorizado.",
              icon: "error",
              confirmButtonText: "Ok",
            });
            break;
          case 403:
            Swal.fire({
              text: "Você não tem permissão para realizar essa operação",
              icon: "error",
              confirmButtonText: "Ok",
            });
            break;
          default:
            break;
        }
        console.log(error.response?.status);
        throw error;
      }
    );

    // Interceptor de requisições
    ApiService.vueInstance.axios.interceptors.request.use((config) => {
      if (!config.url.includes('/enrollments/pix')) {
        loadingStore.show(); // Mostra o loading apenas se a URL não for "/enrollments/pix"
      }
      const token = localStorage.getItem("jwtToken"); // Pega o token armazenado no localStorage
      if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Adiciona o token no cabeçalho
      }
      return config;
    });
  }

  // Métodos HTTP: GET, POST, PUT e DELETE
  /**
   * @description método GET para buscar recursos
   * @param {string} url - Endpoint da API
   * @param {object} params - Parâmetros de consulta (opcional)
   */
  static async get(url, params = {}, headers,) {
    return ApiService.vueInstance.axios.get(url, { params }, { headers});
  }

  /**
   * @description método POST para criar recursos
   * @param {string} url - Endpoint da API
   * @param {object} data - Dados para enviar no body da requisição
   */
  static async post(url, data) {
    return ApiService.vueInstance.axios.post(url, data);
  }

  /**
   * @description método PUT para atualizar recursos
   * @param {string} url - Endpoint da API
   * @param {object} data - Dados para enviar no body da requisição
   */
  static async put(url, data) {
    return ApiService.vueInstance.axios.put(url, data);
  }

  /**
   * @description método PUT para atualizar recursos
   * @param {string} url - Endpoint da API
   * @param {object} data - Dados para enviar no body da requisição
   */
  static async patch(url, data) {
    return ApiService.vueInstance.axios.patch(url, data);
  }

  /**
   * @description método DELETE para excluir recursos
   * @param {string} url - Endpoint da API
   */
  static async delete(url) {
    return ApiService.vueInstance.axios.delete(url);
  }
}

export default ApiService;
