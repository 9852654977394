<template>
  <ul class="main-nav">
    <header-menu></header-menu>
    <li class="login-link">
      <router-link to="/">Login / Signup</router-link>
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  data() {
    return {
    };
  },
};
</script>
