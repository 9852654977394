import { defineStore } from "pinia";

export const useLoadinStore = defineStore("loading-store", {
  state: () => {
    return {
      _loading: false,
      _count: 0,
    };
  },
  actions: {
    show() {
      this._loading = true;
      this._count++;
    },
    hide() {
      this._count--;
      if (this._count === 0) {
        this._loading = false;
      }
    },
  },
  getters: {
    isLoading: (state) => state._loading,
  },
});
