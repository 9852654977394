<template>
  <div>
   <Loading
            v-model:active="loadingStore.isLoading"
            :can-cancel="false"
            :is-full-page="true"
          />
  <page-header v-if="course"></page-header>
  <coursedetails v-if="course"></coursedetails>
  <inner-page v-if="course" :course="course"></inner-page>
  <!-- Course Content -->
  <LoadingPlaceholder :itemCount="4" v-if="isLoading"/>
  <section class="page-content course-sec" v-else>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Overview -->
          <div class="card overview-sec" v-if="isAdmin || isOwner">
            <div class="card-body">
              <h5 class="subs-title">Ações (apenas Instrutor)</h5>
            </div>
            <div class="container">
              <div class="form-group">
                <div class="row" style="padding-left: 1rem;">
                  <div class="col-md-6">
                    <div class="input-block">
                      <label class="add-course-label">Situação do Curso</label>
                      <vue-select :options="['PENDENTE', 'PUBLICADO']" placeholder="Selecione"
                        @select="updateCourse($event)" v-model="course.status" />
                    </div>
                  </div>

                </div>

              </div>
            </div>

          </div>
          <div class="card overview-sec">
            <div class="card-body">
              <h5 class="subs-title">Resumo</h5>
              <!-- <h6>Descrição</h6> -->
              <div v-html="course?.description"></div>
              <!-- <vue-loading :active="isLoading" :can-cancel="false" :is-full-page="true" :color="'#6d65df'"
                :loader="'dots'">
              </vue-loading> -->
              <!-- <h6>What you'll learn</h6>
              <div class="row">
                <div class="col-md-6">
                  <ul>
                    <li>Become a UX designer.</li>
                    <li>You will be able to add UX designer to your CV</li>
                    <li>Become a UI designer.</li>
                    <li>Build & test a full website design.</li>
                    <li>Build & test a full mobile app.</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <ul>
                    <li>Learn to design websites & mobile phone apps.</li>
                    <li>You'll learn how to choose colors.</li>
                    <li>Prototype your designs with interactions.</li>
                    <li>Export production ready assets.</li>
                    <li>All the techniques used by UX professionals</li>
                  </ul>
                </div>
              </div>
              <h6>Requirements</h6>
              <ul class="mb-0">
                <li>
                  You will need a copy of Adobe XD 2023 or above. A free trial
                  can be downloaded from Adobe.
                </li>
                <li>No previous design experience is needed.</li>
                <li class="mb-0">No previous Adobe XD skills are needed.</li>
              </ul> -->
            </div>
          </div>
          <!-- /Overview -->

          <!-- Course Content -->
          <div class="card content-sec">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="subs-title">Conteúdo Preliminar</h5>
                </div>
                <div class="col-sm-6 text-sm-end">
                  <h6>{{ course?.lessonsCount }} Aulas</h6>
                </div>
              </div>
              <div class="course-card" v-for="(modulo, sectionIndex) in course?.modules" :key="sectionIndex">
                <h6 class="cou-title">
                  <a class="collapsed" aria-expanded="false" :data-bs-toggle="'collapse'"
                    :href="'#collapse' + sectionIndex">{{ modulo?.title }}</a>
                </h6>
                <div :id="'collapse' + sectionIndex" class="card-collapse collapse">
                  <ul>
                    <li v-for="(aula, aulaIndex) in modulo.lessons" :key="aula.id">
                      <p>
                        <img src="@/assets/img/icon/play.svg" alt="" class="me-2" />Aula.{{ aulaIndex + 1 }} {{
                        aula.title }}
                      </p>
                      <div>
                        <a @click="selectLessonToView(aula)" 
                          style="cursor: pointer;"
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#view-lesson-modal"
                          v-if="aula.publicAccess">Ver</a>
                          

                        <!-- <span>02:53</span> -->
                      </div>
                    </li>
                    <!-- <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.2 Exercise: Your first design challenge
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.5 How to use text layers effectively
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li> -->
                  </ul>
                </div>
              </div>
              <!-- <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#course2"
                    aria-expanded="false"
                    >The Brief</a
                  >
                </h6>
                <div id="course2" class="card-collapse collapse" style="">
                  <ul>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.1 Introduction to the User Experience Course
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.2 Exercise: Your first design challenge
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.5 How to use text layers effectively
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#course3"
                    aria-expanded="false"
                    >Wireframing Low Fidelity</a
                  >
                </h6>
                <div id="course3" class="card-collapse collapse" style="">
                  <ul>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.1 Introduction to the User Experience Course
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.2 Exercise: Your first design challenge
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture1.5 How to use text layers effectively
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-card">
                <h6 class="cou-title mb-0">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#coursefour"
                    aria-expanded="false"
                    >Type, Color & Icon Introduction</a
                  >
                </h6>
                <div id="coursefour" class="card-collapse collapse" style="">
                  <ul>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture4.1 Introduction to the User Experience Course
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture4.2 Exercise: Your first design challenge
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture4.3 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture4.4 How to solve the previous exercise
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                    <li>
                      <p>
                        <img
                          src="@/assets/img/icon/play.svg"
                          alt=""
                          class="me-2"
                        />Lecture4.5 How to use text layers effectively
                      </p>
                      <div>
                        <a href="javascript:;">Preview</a>
                        <span>02:53</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div> -->
            </div>
          </div>
          <!-- /Course Content -->

          <!-- Instructor -->
          <div class="card instructor-sec">
            <div class="card-body">
              <h5 class="subs-title">Sobre o Instrutor</h5>
              <div class="instructor-wrap">
                <div class="about-instructor">
                  <div class="abt-instructor-img">
                    <router-link to="/instructor/instructor-profile"><img v-if="!course?.instructor?.profileImageUrl"
                        src="@/assets/img/user/user1.jpg" alt="img" class="img-fluid" />
                      <img v-else :src="course?.instructor?.profileImageUrl" alt="img"
                        class="img-fluid" /></router-link>
                  </div>
                  <div class="instructor-detail">
                    <h5>
                      <router-link to="/instructor/instructor-profile">{{ course?.instructor?.name }}</router-link>
                    </h5>
                    <p>{{ course?.instructor?.designation }}</p>
                  </div>
                </div>
                <div class="rating" v-if="false">
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star me-1"></i>
                  <span class="d-inline-block average-rating">4.5 Instructor Rating</span>
                </div>
              </div>
              <div class="course-info d-flex align-items-center" v-if="false">
                <div class="cou-info">
                  <img src="@/assets/img/icon/play.svg" alt="" />
                  <p>5 Courses</p>
                </div>
                <div class="cou-info">
                  <img src="@/assets/img/icon/icon-01.svg" alt="" />
                  <p>12+ Lesson</p>
                </div>
                <div class="cou-info">
                  <img src="@/assets/img/icon/icon-02.svg" alt="" />
                  <p>9hr 30min</p>
                </div>
                <div class="cou-info">
                  <img src="@/assets/img/icon/people.svg" alt="" />
                  <p>270,866 students enrolled</p>
                </div>
              </div>
              <div v-html="course?.instructor?.bio"></div>
              <!-- <p>
                UI/UX Designer, with 7+ Years Experience. Guarantee of High
                Quality Work.
              </p>
              <p>
                Skills: Web Design, UI Design, UX/UI Design, Mobile Design, User
                Interface Design, Sketch, Photoshop, GUI, Html, Css, Grid
                Systems, Typography, Minimal, Template, English, Bootstrap,
                Responsive Web Design, Pixel Perfect, Graphic Design, Corporate,
                Creative, Flat, Luxury and much more.
              </p>

              <p>Available for:</p>
              <ul>
                <li>1. Full Time Office Work</li>
                <li>2. Remote Work</li>
                <li>3. Freelance</li>
                <li>4. Contract</li>
                <li>5. Worldwide</li>
              </ul> -->

            </div>
          </div>
          <!-- /Instructor -->

          <!-- Reviews -->
          <div class="card review-sec" v-if="false">
            <div class="card-body">
              <h5 class="subs-title">Reviews</h5>
              <div class="instructor-wrap">
                <div class="about-instructor">
                  <div class="abt-instructor-img">
                    <router-link to="/instructor/instructor-profile"><img src="@/assets/img/user/user1.jpg" alt="img"
                        class="img-fluid" /></router-link>
                  </div>
                  <div class="instructor-detail">
                    <h5>
                      <router-link to="/instructor/instructor-profile">Nicole Brown</router-link>
                    </h5>
                    <p>UX/UI Designer</p>
                  </div>
                </div>
                <div class="rating">
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star filled me-1"></i>
                  <i class="fas fa-star me-1"></i>
                  <span class="d-inline-block average-rating">4.5 Instructor Rating</span>
                </div>
              </div>
              <p class="rev-info">
                “ This is the second Photoshop course I have completed with
                Cristian. Worth every penny and recommend it highly. To get the
                most out of this course, its best to to take the Beginner to
                Advanced course first. The sound and video quality is of a good
                standard. Thank you Cristian. “
              </p>
              <a href="javascript:;" class="btn btn-reply"><i class="feather-corner-up-left"></i> Reply</a>
            </div>
          </div>
          <!-- /Reviews -->

          <!-- Comment -->
          <div class="card comment-sec" v-if="false">
            <div class="card-body">
              <h5 class="subs-title">Post A comment</h5>
              <form>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Full Name" />
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <div class="form-group">
                      <input type="email" class="form-control" placeholder="Email" />
                    </div>
                  </div>
                </div>
                <div class="form-group mb-3">
                  <input type="email" class="form-control" placeholder="Subject" />
                </div>
                <div class="form-group mb-3">
                  <textarea rows="4" class="form-control" placeholder="Your Comments"></textarea>
                </div>
                <div class="submit-section">
                  <button class="btn submit-btn" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
          <!-- /Comment -->
        </div>

        <div class="col-lg-4">
          <div class="sidebar-sec">
            <!-- Video -->
            <div class="video-sec vid-bg">
              <div class="card">
                <div class="card-body">

                  <LiteYouTubeEmbed v-if="course?.videoUrl" :id="course?.videoUrl?.split('=')[1]" width="320"
                    class="video-thumbnail" />
                  <!-- <a
                    href="https://www.youtube.com/embed/1trvO6dqQUI"
                    class="video-thumbnail"
                    data-fancybox=""
                  > -->
                  <!-- <div class="play-icon" v-if="!course?.videoUrl">
                    <i class="fa-solid fa-play"></i>
                  </div> -->
                  <img class="" src="@/assets/img/video.jpg" alt="" v-if="!course?.coverImage" />
                  <img class="" :src="course?.coverImage" alt="" v-else-if="course?.coverImage && !course?.videoUrl" />
                  
                  <!-- </a> -->
                  <div class="video-details" v-if="!isSubscriber && !course.isPublic">
                    <div class="course-fee" v-if="course?.basePrice && course.enableEnrollment" >
                      <h2>R${{ course?.basePrice }}</h2>
                      <p><span>R${{ (course?.basePrice * 2).toFixed(0) }}</span> 50% off</p>
                    </div>
                    <div class="col-md-12" v-if="course.enableEnrollment">
                    <div class="input-block">
                        <label class="add-course-label">Descontos</label>
                        <vue-select :options="course?.coursePrices" placeholder="Selecione" @select="updateDiscountSelected($event)"/>
                      </div>
                    </div>
                    <div class="row gx-2">
                      <!-- <div class="col-md-6">
                        <router-link
                          to="course-wishlist"
                          class="btn btn-wish w-100"
                          ><i class="feather-heart"></i> Fabvo</router-link
                        >
                      </div> -->
                      <!-- <div class="col-md-12">
                        <a href="javascript:;" class="btn btn-wish w-100"><i class="feather-share-2"></i>
                          Compartilhar</a>
                      </div> -->
                    </div>
                    <a class="btn btn-enroll w-100" v-if="!course.enableEnrollment">Matrículas em Breve</a>
                    <a v-else-if="course.enableEnrollment && isUsuarioLogado" @click="checkout()" class="btn btn-enroll w-100">Comprar Agora</a>
                    <a v-else-if="course.enableEnrollment && !isUsuarioLogado"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#usuarioNaoLogadoModal"
                      class="btn btn-enroll w-100"
                      >Comprar Agora</a
                    >
                  
                  </div>
                  <div v-if="isSubscriber" class="video-details">
                    <div class="course-fee" v-if="course?.basePrice">
                      <h2><span>R$ 0</span></h2>
                      <p><span>R${{ (course?.basePrice * 2).toFixed(0) }}</span> 50% off</p>
                    </div>
                    <div class="input-block">
                        <label class="add-course-label">Gratuito para Assinantes!</label>
                      </div>
                    <a @click="checkoutSubscriber()" class="btn btn-enroll w-100" v-if="isUsuarioLogado">Realizar matricula</a>

                  </div>

                  <div v-if="course.isPublic && course.enableEnrollment" class="video-details">
                    <div class="course-fee" v-if="course?.basePrice">
                      <h2><span>R$ 0</span></h2>
                      <p><span>R${{ (course?.basePrice * 2).toFixed(0) }}</span> 50% off</p>
                    </div>
                    <div class="input-block">
                        <label class="add-course-label">Gratuito!</label>
                      </div>
                    <a @click="checkoutPublic()" class="btn btn-enroll w-100" v-if="isUsuarioLogado">Realizar matricula</a>
                    <a v-else
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#usuarioNaoLogadoModal"
                      class="btn btn-enroll w-100"
                      >Realizar matricula</a
                    >

                  </div>
                  <div v-if="course.isPublic && !course.enableEnrollment" class="video-details">
                    <div class="course-fee" v-if="course?.basePrice">
                      <h2><span>R$ 0</span></h2>
                      <p><span>R${{ (course?.basePrice * 2).toFixed(0) }}</span> 50% off</p>
                    </div>
                    <div class="input-block">
                        <label class="add-course-label">Gratuito!</label>
                      </div>
                    <a  class="btn btn-enroll w-100" v-if="isUsuarioLogado">Matriculas em Breve</a>
                  </div>

                </div>
              </div>
            </div>
            <!-- /Video -->

            <!-- Include -->
            <div class="card include-sec">
              <div class="card-body">
                <div class="cat-title">
                  <h4>Inclui</h4>
                </div>
                <ul>
                  <li>
                    <img src="@/assets/img/icon/import.svg" class="me-2" alt="" />
                    {{ course?.lessonsCount }} Aulas
                  </li>
                  <!-- <li>
                    <img src="@/assets/img/icon/play.svg" class="me-2" alt="" />
                    69 downloadable resources
                  </li> -->
                  <li>
                    <img src="@/assets/img/icon/key.svg" class="me-2" alt="" />
                    Acesso 24hs
                  </li>
                  <li>
                    <img src="@/assets/img/icon/mobile.svg" class="me-2" alt="" />
                    Acesso via Web ou Celular
                  </li>
                  <li>
                    <img src="@/assets/img/icon/cloud.svg" class="me-2" alt="" />
                    Pré-Teste e Pós-Teste
                  </li>
                  <li>
                    <img src="@/assets/img/icon/teacher.svg" class="me-2" alt="" />
                    Certificado de Conclusão
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Include -->

            <!-- Features -->
            <div class="card feature-sec" v-if="false">
              <div class="card-body">
                <div class="cat-title">
                  <h4>Includes</h4>
                </div>
                <ul>
                  <li>
                    <img src="@/assets/img/icon/users.svg" class="me-2" alt="" />
                    Enrolled: <span>32 students</span>
                  </li>
                  <li>
                    <img src="@/assets/img/icon/timer.svg" class="me-2" alt="" />
                    Duration: <span>20 hours</span>
                  </li>
                  <li>
                    <img src="@/assets/img/icon/chapter.svg" class="me-2" alt="" />
                    Chapters: <span>15</span>
                  </li>
                  <li>
                    <img src="@/assets/img/icon/video.svg" class="me-2" alt="" />
                    Video:<span> 12 hours</span>
                  </li>
                  <li>
                    <img src="@/assets/img/icon/chart.svg" class="me-2" alt="" />
                    Level: <span>Beginner</span>
                  </li>
                </ul>
              </div>
            </div>
            <!-- /Features -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <usuario-nao-logado-modal :course="course" :discount="selectedDiscount"></usuario-nao-logado-modal>
  <view-lesson-modal :lesson="selectedLesson"></view-lesson-modal>
  <!-- /Pricing Plan -->
  <layouts1></layouts1>
  <!-- <footer-three></footer-three> -->
</div>
</template>
<script setup>
import ApiService from '@/services/ApiService';
import axiosInstance from '@/axiosConfig';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import { useCourse } from '@/components/composables/usecourse';
import { JWTHandler } from '@/jwtHandler';
import { toCurrency } from '@/utils';

import { storeToRefs } from 'pinia';
import { useLoadinStore } from '@/stores/loadingStore';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import Swal from 'sweetalert2';
const loadingStore = storeToRefs(useLoadinStore());

const prices = ref([]);
const route = useRoute();
const slug = route.params.slug;
console.log('Slug:', slug);
const course = ref(undefined);
const isLoading = ref(true);
const isAdmin = ref(false);
const isOwner = ref(false);
const isStudent = ref(true);
const isUsuarioLogado = ref(JWTHandler.isCurrentTokenValid());
const router = useRouter();
const selectedDiscount = ref();
const isSubscriber = ref(false);
const selectedLesson = ref();
const selectLessonToView = (lesson) => {
  selectedLesson.value = lesson;
}
const updateDiscountSelected = (event) => {
  selectedDiscount.value = event.discountId;
}

const checkoutSubscriber = async () => {
  try {
    if(isUsuarioLogado.value) {
      const response = await ApiService.post('/enrollments/checkout', {courseId: course.value.id});  
      Swal.fire({
            text: "Matricula realizada com sucesso",
            icon: "success",
            confirmButtonText: "Ok",
          });
          router.push('/caduceus/dashboard');
    }
    
  } catch (error) {
    
  }
  
  
};

const checkoutPublic = async () => {
  try {
    const response = await ApiService.post('/enrollments/checkout', {courseId: course.value.id});  
    Swal.fire({
          text: "Matricula realizada com sucesso",
          icon: "success",
          confirmButtonText: "Ok",
        });
        router.push('/caduceus/dashboard');
  } catch (error) {
    
  }
  
  
};

const checkout = () => {
  const { selectedCourse } = useCourse();
  selectedCourse.value = course.value; // Armazena o curso no estado compartilhado
  router.push(`/pages/checkout?course=${course.value.slug}${selectedDiscount.value ? `&discount=${selectedDiscount.value}` : ''}`);
};
onMounted(async () => {
  try {
    const response = await ApiService.get(`/courses/${slug}`);
    course.value = response.data;
    course.value.coursePrices.map(cp => cp['text'] = cp.name + ` ${toCurrency(cp.price)}`)
    // console.log(JWTHandler.getUserData());
    // console.log(`+JWTHandler.getUserData().id === +course.value.instructor?.id`, +JWTHandler.getUserData().id === +course.value.instructor?.id)
    // console.log(`${+JWTHandler.getUserData().id} === ${+course.value.instructor?.id}`, +JWTHandler.getUserData().id === +course.value.instructor?.id)
    isAdmin.value = JWTHandler.isCurrentTokenValid() && JWTHandler.getUserData().isRole('ADMIN');
    console.log('validando')
    isOwner.value = JWTHandler.hasRole('INSTRUCTOR') && +JWTHandler.getUserData().id === +course.value.instructor?.id;
    isStudent.value = JWTHandler.isCurrentTokenValid() && JWTHandler.getUserData().isRole('STUDENT');

    if(JWTHandler.isCurrentTokenValid()) {
      const isSubscriberResponse = await ApiService.get(`/subscriptions/isSubscriber`);
      isSubscriber.value = isSubscriberResponse.data;
    }
    
    

    
    course.value.coursePrices = course.value.coursePrices.filter(cp => cp.onlySubscriber === isSubscriber.value)
    



    const lessonsCount = course.value.modules.reduce(
      (total, module) => total + module.lessons.length,
      0,
    );
    course.value['lessonsCount'] = lessonsCount
  } catch (error) {
    if(error.status == 404) {
      router.push('/error-404')
    }
  } finally {
    isLoading.value = false;
  }


});

const updateCourse = async (newStatus) => {
  try {
    const response = await ApiService.patch(`/courses/${course.value.id}/status`, {
      status: newStatus.id, // Use o novo status diretamente
    });

    console.log('Curso atualizado com sucesso:', response.data);
  } catch (error) {
    console.error('Erro ao atualizar curso:', error);
  }
};
</script>
