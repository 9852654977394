<template>
  <div class="modal fade" data-bs-backdrop='static' id="view-lesson-modal">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>{{ lesson?.title }}</h4>
              </div>
              <button type="button" class="close" @click="stopVideo()" data-bs-dismiss="modal" aria-label="Close">
                <i class="feather-x"></i>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <LiteYouTubeEmbed v-if="lesson?.contentUrl && lesson?.contentType === 'YOUTUBE'" ref="iframe"
                :id="'goiWrNiaT0I'" width="320" class="video-thumbnail" js-api />
                <!-- <lite-youtube videoid="goiWrNiaT0I" js-api></lite-youtube> -->
              <!-- <div class="youtube-player" v-if="lesson?.contentUrl && lesson?.contentType === 'YOUTUBE'"></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { watch } from 'vue';
import { onMounted } from 'vue';
import { defineProps } from 'vue';
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
const iframe = ref(null)

const props = defineProps({
  lesson: {
    type: Object,
    required: true,
  },
});

// watch(
//   () => props.lesson, // Observa a prop `lesson`
//   (newLesson, oldLesson) => {
//     console.log('A prop lesson mudou!');
//     console.log('Novo valor:', newLesson);
//     console.log('Valor antigo:', oldLesson);

//     // Aqui você pode adicionar lógica para reagir à mudança
//     // if (newLesson.title !== oldLesson.title) {
//     //   console.log('O título da lição mudou!');
//     // }
//   },
//   { deep: true } // Observa mudanças profundas no objeto
// );
// onMounted(() => {
//   loadYouTubeAPI();
// });
// const player = ref();
// const loadYouTubeAPI = () => {
//   console.log('Montandos')
//   if (!window.YT) {
//       const scriptTag = document.createElement('script');
//       scriptTag.src = "https://www.youtube.com/iframe_api";
//       document.body.appendChild(scriptTag);
//       window.onYouTubeIframeAPIReady = initializePlayer;
//     } else {
//       initializePlayer(); // Caso o script já esteja carregado
//     }
  
// };

// const initializePlayer = () => {
//   // Verifica se a API está disponível
//   if (window.YT && window.YT.Player) {
//     player.value = new YT.Player('youtube-player', {
//       height: '360',
//       width: '640',
//       videoId: props.lesson?.contentUrl?.split('=')[1], // Video inicial
//       events: {
//         'onReady': onPlayerReady,
//         'onStateChange': onPlayerStateChange
//       }
//     });
//   }
// }
// const onPlayerReady = (event) => {
//   // O player está pronto
// };
// const onPlayerStateChange = (event) => {
//   // O estado do player mudou
// };
// const playVideo = () => {
//   player.value.playVideo();
// };
// const pauseVideo = () => {
//   player.value.pauseVideo();
// }
const stopVideo = () => {
  iframe.value?.stopVideo()

}
// Dados reativos usando `ref`
</script>