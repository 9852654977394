<template>
    <div class="row">
      <login-banner></login-banner>

      <div class="col-md-6 login-wrap-bg">
        <!-- Login -->
        <div class="login-wrapper">
          <div class="loginbox">
            <div class="img-logo">
              <img src="@/assets/img/caduceus.png" class="img-fluid" alt="Logo" />
              <div class="back-home">
                <router-link to="/home/">Voltar para Home</router-link>
              </div>
            </div>
            <h1>Criar uma conta</h1>
            <Form
              class="register"
              @submit="onSubmit"
              :validation-schema="schema">
              <div class="form-group ">
                <label class="form-control-label mb-2">Nome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="fullName"
                  placeholder="Informe seu nome completo"
                />
                <div class="invalid-feedback">{{ errors.fullName }}</div>
                <div class="text-danger" id="firstname"></div>

              </div>
              <div class="form-group ">
                <label class="form-control-label mb-2">Celular</label>
                <input
                  type="text"
                  v-mask="'###########'"
                  class="form-control"
                  v-model="mobilePhone"
                  placeholder="DDD+TELEFONE"
                />
                <div class="invalid-feedback">{{ errors.fullName }}</div>
                <div class="text-danger" id="firstname"></div>

              </div>
<!-- 
              <div class="form-group">
                <label class="form-control-label mb-2">Sobrenome</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="lastname"
                  placeholder="Enter your Sobrenome"
                />
                <div class="invalid-feedback">{{ errors.lastname }}</div>
                <div class="text-danger" id="lastname"></div>

              </div> -->
              <div class="form-group">
                <label class="form-control-label mb-2">Email</label>
                <Field
                  name="email"
                  type="text"
                  v-model="email"
                  :class="{ 'is-invalid': errors.email }"
                  class="form-control "
                />
              </div>
              <div class="form-group">
                <label class="form-control-label mb-2">Password</label>
                <div class="pass-group" id="passwordInput">
                  <input
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input"
                    :class="{ 'password-error': validationError }"
                    v-model="password"
                    @input="handlePasswordChange"
                  />
                  <span @click="toggleShow" class="toggle-password">
                    <i
                      :class="{
                        'fas fa-eye': showPassword,
                        'fas fa-eye-slash': !showPassword,
                      }"
                    ></i>
                  </span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>

                  <!-- Conditionally render password strength only if there is a password -->
                  <div
                    v-if="password"
                    id="passwordStrength"
                    style="display: flex"
                    :class="[
                      'password-strength',
                      strength === 'poor' ? 'poor-active' : '',
                      strength === 'weak' ? 'avg-active' : '',
                      strength === 'strong' ? 'strong-active' : '',
                      strength === 'heavy' ? 'heavy-active' : '',
                    ]"
                  >
                    <span
                      id="poor"
                      class="active"
                      :class="{ active: strength === 'poor' }"
                    ></span>
                    <span
                      id="weak"
                      class="active"
                      :class="{ active: strength === 'weak' }"
                    ></span>
                    <span
                      id="strong"
                      class="active"
                      :class="{ active: strength === 'strong' }"
                    ></span>
                    <span
                      id="heavy"
                      class="active"
                      :class="{ active: strength === 'heavy' }"
                    ></span>
                  </div>

                  <!-- Conditionally render password information only if there is a password -->
                  <div v-if="password" id="passwordInfo">
                    <span v-if="validationError === 1"></span>
                    <span v-else-if="validationError === 2" style="color: red"
                      >😠 Weak. Must contain at least 8 characters</span
                    >
                    <span v-else-if="validationError === 3" style="color: orange"
                      >😲 Average. Must contain at least 1 letter or number</span
                    >
                    <span v-else-if="validationError === 4" style="color: blue"
                      >🙂 Almost. Must contain a special symbol</span
                    >
                    <span v-else-if="validationError === 5" style="color: green"
                      >😊 Awesome! You have a secure password.</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-check remember-me">
                <label class="form-check-label mb-0">
                  <input class="form-check-input" type="checkbox" name="remember" v-model="agree"/> Eu aceito os
                  <router-link to="/pages/term-condition">Termos de Serviço</router-link> e as 
                  <router-link to="/pages/privacy-policy">Políticas de Privacidade.</router-link>
                </label>
              </div>
              <div class="d-grid">
                <button class="btn btn-primary btn-start" type="button" @click="onSubmit">
                  Create Account
                </button>
              </div>
            </Form>
          </div>
          <div class="google-bg text-center" v-if="false">
            <span><a href="javascript:void(0);">Or sign in with</a></span>
            <div class="sign-google">
              <ul>
                <li>
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/net-icon-01.png"
                      class="img-fluid"
                      alt="Logo"
                    />
                    Sign In using Google</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);"
                    ><img
                      src="@/assets/img/net-icon-02.png"
                      class="img-fluid"
                      alt="Logo"
                    />Sign In using Facebook</a
                  >
                </li>
              </ul>
            </div>
            <p class="mb-0">
              Already have an account? <router-link to="/">Sign in</router-link>
            </p>
          </div>
        </div>
        <!-- /Login -->
      </div>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import axios from 'axios'
import ApiService from '@/services/ApiService';
import axiosInstance from "@/axiosConfig";

const $toast = useToast();

// Estados Reativos
const fullName = ref("");
const mobilePhone = ref("");
const email = ref("");
const password = ref("");
const agree = ref(false);

const showPassword = ref(false);
const validationError = ref(0);
const strength = ref("");
const errors = ref({
  password: "",
});

// Router para navegação
const router = useRouter();

// Computed Property para alternar rótulo do botão de exibir/ocultar senha
const buttonLabel = computed(() => (showPassword.value ? "Hide" : "Show"));

// Esquema de Validação com Yup
const schema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

// Função para manipular a submissão do formulário
const onSubmit = async () => {

  if(!fullName.value) {
    $toast.error('Informe seu nome completo', {
      timeout: 8000,
      position: "top"
    });
    return;
  }


  if(!password.value) {
    $toast.error('Senha inválida', {
      timeout: 8000,
      position: "top"
    });
    return;
  }

  if(!email.value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value)) {
    $toast.error('Email inválido', {
      timeout: 8000,
      position: "top"
    });
    return;
  }

  if(!agree.value) {
    $toast.error('You must accept Terms of Service.', {
      timeout: 8000,
      position: "top"
    });
    return;
  }

  // schema.validate({email: email.value, password: password.value, fullname: fullname.value});
  console.log('ok');
  try {

    const response = await ApiService.post(`/auth/signup`, {
      email: email.value,
      fullName: fullName.value,
      password: password.value,
      mobilePhone: mobilePhone.value
    });
    const token = response.data; // Supondo que o token seja retornado no campo `token`
    if (token) {
      // Armazena o token no localStorage
      localStorage.setItem('jwtToken', token);
      $toast.success('Account created successfully!', {
        timeout: 8000,
        position: "top"
      });
      router.push("/home");
    }
  } catch (error) {
    console.log(error);
    $toast.error(error, {
        timeout: 8000,
        position: "top"
      });
    // errorMessage.value = 'Erro ao carregar os dados do usuário.'
  }
};

// Função para alternar exibição de senha
const toggleShow = () => {
  showPassword.value = !showPassword.value;
};

// Função para manipular a validação da senha
const handlePasswordChange = () => {
  const passwordValue = password.value;
  const passwordLength = passwordValue.length;
  const poorPassword = /[a-z]/.test(passwordValue);
  const weakPassword = /(?=.*?[0-9])/.test(passwordValue);
  const strongPassword = /(?=.*?[#?!@$%^&*-])/.test(passwordValue);
  const whitespace = /^\s*$/.test(passwordValue);

  if (passwordValue !== "") {
    if (whitespace) {
      errors.value.password = "Whitespaces are not allowed";
    } else {
      errors.value.password = "";
      poorPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
      weakPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
      strongPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
      heavyPasswordStrength(passwordLength, poorPassword, weakPassword, strongPassword);
    }
  } else {
    errors.value.password = "";
    validationError.value = 0;
    strength.value = "";
  }
};

// Funções de validação de força da senha
const poorPasswordStrength = (passwordLength, poorPassword, weakPassword, strongPassword) => {
  if (passwordLength < 8) {
    validationError.value = 2;
    strength.value = "poor";
  }
};

const weakPasswordStrength = (passwordLength, poorPassword, weakPassword, strongPassword) => {
  if (passwordLength >= 8 && (poorPassword || weakPassword || strongPassword)) {
    validationError.value = 3;
    strength.value = "weak";
  }
};

const strongPasswordStrength = (passwordLength, poorPassword, weakPassword, strongPassword) => {
  if (passwordLength >= 8 && poorPassword && (weakPassword || strongPassword)) {
    validationError.value = 4;
    strength.value = "strong";
  }
};

const heavyPasswordStrength = (passwordLength, poorPassword, weakPassword, strongPassword) => {
  if (passwordLength >= 8 && poorPassword && weakPassword && strongPassword) {
    validationError.value = 5;
    strength.value = "heavy";
  }
};
</script>
