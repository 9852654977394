<template>
    <layouts-login></layouts-login>
    <blog-modern></blog-modern>

    <!-- Blog Modern -->
    <section class="course-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-14.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Learn Webs Applications Development from Experts</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />Jan 20, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Angular
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-15.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Expand Your Career Opportunities With Python</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />Jun 10, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Web Design
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-16.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Learn Mobile Applications Development from Experts</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />March 14, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, React
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-17.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Complete PHP Programming Career Guideline</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />Jun 19, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Web Design
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-18.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Programming Content Guideline For Beginners</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />Nov 05, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Php
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-19.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >The Complete JavaScript Course for Beginners</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />May 11, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Web Design
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-14.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Learn Webs Applications Development from Experts</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />Jun 14, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Course
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-15.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Expand Your Career Opportunities With Python</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />May 18, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Web Design
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Blog Post -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-16.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Learn Mobile Applications Development from Experts</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />Jun 23, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programming, Course
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Blog Post -->
              </div>
            </div>

            <pagination></pagination>
          </div>
        </div>
      </div>
    </section>
    <!-- /Blog Modern -->

    <layouts1></layouts1>
</template>
