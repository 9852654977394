<template>
  <div>
  <div class="profile-heading">
    <h3>Configurações</h3>
    <p>Você tem gerenciar os dados da sua conta</p>
  </div>
  <div class="settings-page-head">
    <ul class="settings-pg-links">
      <li>
        <router-link to="/caduceus/settings"
          ><i class="bx bx-edit"></i>Editar Perfil</router-link
        >
      </li>
      <li>
        <router-link to="/caduceus/change-password"
          ><i class="bx bx-lock"></i>Mudar Password</router-link
        >
      </li>
      <!-- <li>
        <router-link to="/caduceus/setting-notifications"
          ><i class="bx bx-bell"></i>Notifications</router-link
        >
      </li>
      <li>
        <router-link to="/caduceus/setting-withdraw"
          ><i class="bx bx-wallet-alt"></i>Withdraw</router-link
        >
      </li>
      <li>
        <router-link to="/caduceus/delete-account"
          ><i class="bx bx-error-alt"></i>Delete Account</router-link
        >
      </li> -->
    </ul>
  </div>
</div>
</template>
