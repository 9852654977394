<template>
<div class="col-xl-9 col-lg-9">
          <!-- Dashboard Grid -->
          <div class="row justify-content-center" v-if="false">
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Matriculados</h5>
                  <h2>12</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Ativos</h5>
                  <h2>03</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Completados</h5>
                  <h2>13</h2>
                </div>
              </div>
            </div>
          </div>
          <!-- /Dashboard Grid -->

          <div class="col-xl-12 col-lg-12">

<div class="settings-widget card-info">
    <div class="settings-menu p-0">
        <div class="profile-heading">
            <h3>Meus Cursos</h3>
        </div>
        <div class="checkout-form pb-0">
            <div class="wishlist-tab">
                <ul class="nav">
                    <!-- <li class="nav-item">
                        <a href="javascript:void(0);" class="active" data-bs-toggle="tab"
                            data-bs-target="#enroll-courses">Matriculados (06)</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0);" data-bs-toggle="tab"
                            data-bs-target="#active-courses">Ativos (03)</a>
                    </li>
                    <li class="nav-item">
                        <a href="javascript:void(0);" data-bs-toggle="tab"
                            data-bs-target="#complete-courses">Finalizados (03)</a>
                    </li> -->
                </ul>
            </div>

            <div class="tab-content">
                <div class="tab-pane fade show active" id="enroll-courses">
                    <EnrolledCourses :enrollments="enrollments"/>
                </div>

                <!-- <div class="tab-pane fade" id="active-courses">
                    <instructor-active-course></instructor-active-course>
                </div>

                <div class="tab-pane fade" id="complete-courses">
                    <instructor-completed-course></instructor-completed-course>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="dash-pagination" v-if="false">
    <div class="row align-items-center">
        <div class="col-6">
            <p>Page 1 of 2</p>
        </div>
        <div class="col-6">
            <ul class="pagination">
                <li class="active">
                    <a href="#">1</a>
                </li>
                <li>
                    <a href="#">2</a>
                </li>
                <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                </li>
            </ul>
        </div>
    </div>
</div>

</div>
        </div>
</template>
<script setup>
import ApiService from '@/services/ApiService';
import axiosInstance, { tratarError } from '@/axiosConfig';
import { onMounted, ref } from 'vue';
import EnrolledCourses from './enrolled-courses.vue';

const title = ref("Dashboard");
const text = ref("Home");
const text1 = ref("Dashboard");
const enrollments = ref([]);

onMounted(async () => {
    try {
        const response = await ApiService.get('/courses/me');
        enrollments.value = response.data;
    } catch (error) {
        tratarError(error)
    }    
})

</script>