<template>
    <layouts-loginborder></layouts-loginborder>
    <job-category></job-category>
    <!-- Page Wrapper -->
    <div class="page-content">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="title-sec">
              <h2>Browse Job By Categories</h2>
            </div>

            <!-- Category Tab -->
            <div class="category-tab">
              <ul class="nav nav-justified">
                <li class="nav-item">
                  <a href="#graphics" class="nav-link active" data-bs-toggle="tab"
                    >Graphics & Design</a
                  >
                </li>
                <li class="nav-item">
                  <a href="#programming" class="nav-link" data-bs-toggle="tab"
                    >Programming & Tech</a
                  >
                </li>
                <li class="nav-item">
                  <a href="#marketing" class="nav-link" data-bs-toggle="tab"
                    >Digital Marketing</a
                  >
                </li>
                <li class="nav-item">
                  <a href="#animation" class="nav-link" data-bs-toggle="tab"
                    >Video & Animation</a
                  >
                </li>
              </ul>
            </div>
            <!-- /Category Tab -->

            <!-- Category List -->
            <div class="tab-content">
              <div class="tab-pane fade show active" id="graphics">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-01.jpg" alt="" />
                        </div>
                        <h5>Logo Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-02.jpg" alt="" />
                        </div>
                        <h5>Business Cards & Stationery</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-03.jpg" alt="" />
                        </div>
                        <h5>Brochure Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-04.jpg" alt="" />
                        </div>
                        <h5>Social Media Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-05.jpg" alt="" />
                        </div>
                        <h5>Graphics for Streamers</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Photoshop Editing</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-07.jpg" alt="" />
                        </div>
                        <h5>Brand Style Guides</h5>
                      </div>
                      <span class="cat-count">25</span>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-08.jpg" alt="" />
                        </div>
                        <h5>Illustration</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-09.jpg" alt="" />
                        </div>
                        <h5>Flyer Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-10.jpg" alt="" />
                        </div>
                        <h5>Icon Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-11.jpg" alt="" />
                        </div>
                        <h5>Packaging & Label Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-12.jpg" alt="" />
                        </div>
                        <h5>Presentation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-13.jpg" alt="" />
                        </div>
                        <h5>Game Art</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-14.jpg" alt="" />
                        </div>
                        <h5>Pattern Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-15.jpg" alt="" />
                        </div>
                        <h5>Book Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-16.jpg" alt="" />
                        </div>
                        <h5>Invitation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-17.jpg" alt="" />
                        </div>
                        <h5>UX Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Infographic Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="programming">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-03.jpg" alt="" />
                        </div>
                        <h5>Brochure Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-04.jpg" alt="" />
                        </div>
                        <h5>Social Media Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-05.jpg" alt="" />
                        </div>
                        <h5>Graphics for Streamers</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Photoshop Editing</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-09.jpg" alt="" />
                        </div>
                        <h5>Flyer Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-10.jpg" alt="" />
                        </div>
                        <h5>Icon Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-11.jpg" alt="" />
                        </div>
                        <h5>Packaging & Label Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-12.jpg" alt="" />
                        </div>
                        <h5>Presentation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-15.jpg" alt="" />
                        </div>
                        <h5>Book Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-16.jpg" alt="" />
                        </div>
                        <h5>Invitation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-17.jpg" alt="" />
                        </div>
                        <h5>UX Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Infographic Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="marketing">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-04.jpg" alt="" />
                        </div>
                        <h5>Social Media Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-05.jpg" alt="" />
                        </div>
                        <h5>Graphics for Streamers</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Photoshop Editing</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-10.jpg" alt="" />
                        </div>
                        <h5>Icon Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-11.jpg" alt="" />
                        </div>
                        <h5>Packaging & Label Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-12.jpg" alt="" />
                        </div>
                        <h5>Presentation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-16.jpg" alt="" />
                        </div>
                        <h5>Invitation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-17.jpg" alt="" />
                        </div>
                        <h5>UX Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>

                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Infographic Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane fade" id="animation">
                <div class="row">
                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-05.jpg" alt="" />
                        </div>
                        <h5>Graphics for Streamers</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-04.jpg" alt="" />
                        </div>
                        <h5>Social Media Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Photoshop Editing</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-11.jpg" alt="" />
                        </div>
                        <h5>Packaging & Label Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-10.jpg" alt="" />
                        </div>
                        <h5>Icon Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-12.jpg" alt="" />
                        </div>
                        <h5>Presentation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4 col-md-6">
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-17.jpg" alt="" />
                        </div>
                        <h5>UX Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-16.jpg" alt="" />
                        </div>
                        <h5>Invitation Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                    <div class="category-box">
                      <div class="category-title">
                        <div class="category-img">
                          <img src="@/assets/img/category/category-06.jpg" alt="" />
                        </div>
                        <h5>Infographic Design</h5>
                      </div>
                      <div class="cat-count">
                        <span>25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Category List -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Page Wrapper -->

    <layouts1></layouts1>
</template>
