<template>
  <student-header></student-header>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <student-sidebar></student-sidebar>
        <!-- /Sidebar -->

        <!-- Student Configurações -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Configurações</h3>
                <p>Você tem gerenciar os dados da sua conta</p>
              </div>
              <settings-sidebar></settings-sidebar>
              <div class="checkout-form settings-wrap">
                <h5>Choose when and how to be notified</h5>
                <ul class="settings-noti-lists">
                  <li>
                    <div class="notification-title">
                      <h6>Subscriptions</h6>
                      <p>
                        Notify me about activity from profile I’m subscribe to
                      </p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user1"
                        class="check"
                        checked=""
                      />
                      <label for="user1" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Recommended Courses</h6>
                      <p>Notify me about courses that suits for me</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user2"
                        class="check"
                        checked=""
                      />
                      <label for="user2" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Reply to my comments</h6>
                      <p>Notify me about replies for my comments</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user3"
                        class="check"
                        checked=""
                      />
                      <label for="user3" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Activity on my comments</h6>
                      <p>Notify me about replies for my comments</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input type="checkbox" id="user4" class="check" />
                      <label for="user4" class="checktoggle"> </label>
                    </div>
                  </li>
                </ul>
                <h5>Email Notifications</h5>
                <ul class="settings-noti-lists email-noti-lists">
                  <li>
                    <div class="notification-title">
                      <h6>Subscriptions</h6>
                      <p>
                        Notify me about activity from profile I’m subscribe to
                      </p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user5"
                        class="check"
                        checked=""
                      />
                      <label for="user5" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Recommended Courses</h6>
                      <p>Notify me about courses that suits for me</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user6"
                        class="check"
                        checked=""
                      />
                      <label for="user6" class="checktoggle"> </label>
                    </div>
                  </li>
                  <li>
                    <div class="notification-title">
                      <h6>Reply to my comments</h6>
                      <p>Notify me about replies for my comments</p>
                    </div>
                    <div class="status-toggle modal-status">
                      <input
                        type="checkbox"
                        id="user7"
                        class="check"
                        checked=""
                      />
                      <label for="user7" class="checktoggle"> </label>
                    </div>
                  </li>
                </ul>
                <a href="#" class="btn btn-primary">Salvar Changes</a>
              </div>
            </div>
          </div>
        </div>
        <!-- /Student Configurações -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: "Configurações",
      text: "Home",
      text1: "Notifications",
    };
  },
};
</script>
