<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Configurações -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
             
              <instructor-settings-sidebar></instructor-settings-sidebar>
              <form action="/instructor-change-password">
                <div class="checkout-form settings-wrap">
                  <div class="row">
    <div class="col-md-6">
      <div class="input-block">
        <label class="form-label">Current Password</label>
        <input
          type="password"
          class="form-control"
          v-model="currentPassword"
        />
      </div>
      <div class="input-block">
        <label class="form-label">New Password</label>
        <input
          type="password"
          class="form-control"
          v-model="newPassword"
        />
      </div>
      <div class="input-block">
        <label class="form-label">Re-type New Password</label>
        <input
          type="password"
          class="form-control"
          v-model="confirmNewPassword"
        />
      </div>
      <button
        class="btn btn-primary"
        type="button"
        @click="resetPassword"
      >
        Reset Password
      </button>

      <!-- Exibe mensagens de erro ou sucesso -->
      <div v-if="errorMessage" class="text-danger mt-3">{{ errorMessage }}</div>
      <div v-if="successMessage" class="text-success mt-3">{{ successMessage }}</div>
    </div>
  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Instructor Configurações -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import axios from 'axios'

// Estados reativos para os campos do formulário
const currentPassword = ref('')
const newPassword = ref('')
const confirmNewPassword = ref('')
const errorMessage = ref('')
const successMessage = ref('')
const title = ref("Configurações");
const text = ref("Home");
const text1 = ref("Mudar Password");
// Função para resetar a senha
const resetPassword = async () => {
  try {
    // Validação básica
    if (newPassword.value !== confirmNewPassword.value) {
      errorMessage.value = 'As senhas não coincidem.'
      successMessage.value = ''
      return
    }

    // Payload para a requisição
    const payload = {
      currentPassword: currentPassword.value,
      newPassword: newPassword.value,
    }

    // Chamada ao backend
    const response = await axios.patch('/api/auth/reset-password', payload)

    // Sucesso
    successMessage.value = response.data.message || 'Senha alterada com sucesso!'
    errorMessage.value = ''
    currentPassword.value = ''
    newPassword.value = ''
    confirmNewPassword.value = ''
  } catch (error) {
    // Erro no backend
    errorMessage.value = error.response?.data?.message || 'Erro ao alterar a senha.'
    successMessage.value = ''
  }
}
</script>
<script>
export default {
  data() {
    return {
      title: "Configurações",
      text: "Home",
      text1: "Mudar Password",
    };
  },
};
</script>
