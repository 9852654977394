<template>
  <div class="tab-pane show active" id="all">
    <div class="table-responsive custom-table">
      <!-- Referred Users-->
      <table class="table table-nowrap mb-0">
        <thead>
          <tr>
            <th>Ticket ID</th>
            <th>Date</th>
            <th>Subject</th>
            <th>Priority</th>
            <th>Category</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Ticket#001</td>
            <td>March 12, 2024</td>
            <td>Need a freelancer software</td>
            <td>
              <span class="resut-badge badge-light-success">Low</span>
            </td>
            <td>Mailing Issues</td>
            <td>
              <span class="status-badge badge-soft-success">Opened</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#002</td>
            <td>March 18, 2024</td>
            <td>I have a problem</td>
            <td>
              <span class="resut-badge badge-light-danger">High</span>
            </td>
            <td>Language Issues</td>
            <td>
              <span class="status-badge badge-soft-warning">Inprogress</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#003</td>
            <td>March 27, 2024</td>
            <td>Account Activation mail not received</td>
            <td>
              <span class="resut-badge badge-light-danger">High</span>
            </td>
            <td>Mailing Issues</td>
            <td>
              <span class="status-badge badge-soft-danger">Closed</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#004</td>
            <td>April 04, 2024</td>
            <td>Enabling SSH service</td>
            <td>
              <span class="resut-badge badge-light-warning">Medium</span>
            </td>
            <td>Installation Error</td>
            <td>
              <span class="status-badge badge-soft-success">Opened</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#005</td>
            <td>April 24, 2024</td>
            <td>Payment Processed but not showed</td>
            <td>
              <span class="resut-badge badge-light-success">Low</span>
            </td>
            <td>Payment Issues</td>
            <td>
              <span class="status-badge badge-soft-danger">Closed</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#006</td>
            <td>April 28, 2024</td>
            <td>When will start the order</td>
            <td>
              <span class="resut-badge badge-light-danger">High</span>
            </td>
            <td>Demo Problem</td>
            <td>
              <span class="status-badge badge-soft-warning">Inprogress</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#007</td>
            <td>June 03, 2024</td>
            <td>Slow speed while Course Download</td>
            <td>
              <span class="resut-badge badge-light-danger">High</span>
            </td>
            <td>Server Issues</td>
            <td>
              <span class="status-badge badge-soft-success">Opened</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#008</td>
            <td>June 13, 2024</td>
            <td>Unable to access the course</td>
            <td>
              <span class="resut-badge badge-light-warning">Medium</span>
            </td>
            <td>Demo Problem</td>
            <td>
              <span class="status-badge badge-soft-success">Opened</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#009</td>
            <td>July 15, 2024</td>
            <td>Assignment Upload Error files</td>
            <td>
              <span class="resut-badge badge-light-success">Low</span>
            </td>
            <td>File Error</td>
            <td>
              <span class="status-badge badge-soft-success">Opened</span>
            </td>
          </tr>
          <tr>
            <td>Ticket#0010</td>
            <td>July 23, 2024</td>
            <td>Account Recovery Assistance Need</td>
            <td>
              <span class="resut-badge badge-light-danger">High</span>
            </td>
            <td>Recovery Issues</td>
            <td>
              <span class="status-badge badge-soft-success">Opened</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
