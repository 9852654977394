<template>
    <horizontal-header></horizontal-header>
    <home-banner></home-banner>
    <feature-course></feature-course>
    <trending-course></trending-course>
    <share-knowledge></share-knowledge>
    <latest-blog></latest-blog>
    <layouts1></layouts1>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
