<template>
  <div class="row">
    <login-banner></login-banner>

    <login-div></login-div>
  </div>
</template>

<script>
import { ref } from "vue";
import { router } from "@/router";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import ApiService from '@/services/ApiService';
import axiosInstance from "@/axiosConfig";

const $toast = useToast();

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      password: null,
      emailError: "",
      passwordError: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
  },
  setup() {
    // let users = localStorage.getItem("storedData");
    // if (users === null) {
    //   let password = [
    //     {
    //       email: "example@dreamstechnologies.com",
    //       password: "123456",
    //     },
    //   ];
    //   const jsonData = JSON.stringify(password);
    //   localStorage.setItem("storedData", jsonData);
    // }
    const schema = Yup.object().shape({
      email: Yup.string().required("Email is required").email("Email is invalid"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    });
    const onSubmit = async (values) => {
      try {
        const response = await ApiService.post("auth/signin", values)
        // console.log(response);

        
          const token = response.data; // Supondo que o token seja retornado no campo `token`
        if (token) {
          // Armazena o token no localStorage
          localStorage.setItem('jwtToken', token);
          $toast.success('Login successfully!', {
            timeout: 8000,
            position: "top"
          });
          router.push("/home");
        }
      } catch (error) {
        if(error.status === 401) {
          $toast .error("Invalide email or password", {
            position: "top"
          })
        }
        
        
      }
    };
    return {
      schema,
      onSubmit,
      checked: ref(false),
    };
  },
};
</script>