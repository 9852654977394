export class JWTHandler {
    // Obter o token do localStorage
    static getToken() {
      return localStorage.getItem("jwtToken");
    }
  

    static logout() {
      return localStorage.removeItem("jwtToken");
    }
  
    // Armazenar o token no localStorage
    static setToken(token) {
      localStorage.setItem("jwtToken", token);
    }
    // Decodificar o JWT
    static decodeToken(token) {
      try {
        const payload = token.split(".")[1];
        const decodedPayload = atob(payload);
        return JSON.parse(decodedPayload);
      } catch (error) {
        console.error("Invalid JWT token:", error);
        return null;
      }
    }
  
    // Verificar se o token é válido
    static isTokenValid(token) {
      if (!token) return false;
      const decoded = this.decodeToken(token);
      if (!decoded || !decoded.exp) return false;
  
      // Verificar o tempo de expiração
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp > currentTime;
    }

    // Verificar se o token é válido
    static isCurrentTokenValid() {
      const token = this.getToken();
      if (!token) return false;
      const decoded = this.decodeToken(token);
      if (!decoded || !decoded.exp) return false;
  
      // Verificar o tempo de expiração
      const currentTime = Math.floor(Date.now() / 1000);
      return decoded.exp > currentTime;
    }
  
    // Obter os dados do usuário do JWT
    static getUserData() {
      const token = this.getToken();
      if (!this.isTokenValid(token)) return undefined;
  
      const decoded = this.decodeToken(token);
      return {
        id: decoded.id,
        email: decoded.email,
        role: decoded.role,
        profileImageUrl: decoded.profileImageUrl || null,
        fullName: decoded.fullName || null,
        isRole: (role) => {
          if(this.isCurrentTokenValid()) {
            if(decoded.role === role) {
                return true;
            } else return false
    
          } else 
              return false
          }
      };
    }

    static hasRole(role) {
      const token = this.getToken();
      if (!this.isTokenValid(token)) return false;
      if(this.isCurrentTokenValid()) {
        const decoded = this.decodeToken(token);
        if(decoded.role === role) {
            return true;
        } else return false

      } else 
          return false
    }
  }
