<template>
<!-- Footer Top -->
<div class="footer-top">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
            
                <!-- Footer Widget -->
                <div class="footer-widget footer-about">
                    <div class="footer-logo">
                        <img src="@/assets/img/caduceus.png" alt="logo">
                    </div>
                    <div class="footer-about-content">
                        <p>
                    Referência em pesquisa, formação, soluções e tecnologias para prática de saúde, o Instituto Caduceus – Centro de Estudos, Pesquisas e Capacitação Multidisciplinar foi criado em 1994 para oferecer cursos regulares via telemedicina, presenciais e semipresenciais e um programa de educação continuada em prol do diagnóstico e tratamento das cardiopatias congênitas no feto, na criança, adolescente e no adulto.
                  </p>
                  <p>
                    Equipe de pesquisadores coordenada pela diretora técnica médica Dra Sandra Mattos, MD PhD (Lattes) utiliza tecnologia inovadora e pioneira, com técnicas de simulação realística, discussões de casos clínicos e aulas práticas, estágios de imersão e especialização nas áreas foco, para diferentes profissionais de saúde.
                  </p>
                    </div>
                </div>
                <!-- /Footer Widget -->
                
            </div>
            
            <div class="col-lg-2 col-md-6" v-if="false">
            
                <!-- Footer Widget -->
                <div class="footer-widget footer-menu">
                    <h2 class="footer-title">For Instructor</h2>
                    <ul>
                        <li><router-link to="/instructor/instructor-profile">Perfil</router-link></li>
                        <li><router-link to="/login">Login</router-link></li>
                        <li><router-link to="/register">Register</router-link></li>
                        <li><router-link to="/instructor/instructor-list">Instructor</router-link></li>
                        <li><router-link to="/instructor/instructor-dashboard"> Dashboard</router-link></li>
                    </ul>
                </div>
                <!-- /Footer Widget -->
                
            </div>
            
            <div class="col-lg-2 col-md-6" v-if="false">
            
                <!-- Footer Widget -->
                <div class="footer-widget footer-menu">
                    <h2 class="footer-title">For Student</h2>
                    <ul>
                        <li><router-link to="/student/student-profile">Perfil</router-link></li>
                        <li><router-link to="/login">Login</router-link></li>
                        <li><router-link to="/register">Register</router-link></li>
                        <li><router-link to="/student/students-list">Student</router-link></li>
                        <li><router-link to="/student/student-dashboard"> Dashboard</router-link></li>
                    </ul>
                </div>
                <!-- /Footer Widget -->
                
            </div>
            
            <div class="col-lg-4 col-md-6">
            
                <!-- Footer Widget -->
                <div class="footer-widget footer-contact">
                    <!-- <h2 class="footer-title">News letter</h2>
                    <div class="news-letter">
                        <form>
                            <input type="text" class="form-control" placeholder="Enter your email address" name="email">
                        </form>
                    </div> -->
                    <div class="footer-contact-info">
                        <div class="footer-address">
                            <img src="@/assets/img/icon/icon-20.svg" alt="" class="img-fluid">
                            <p> R. N S da Glória, 203 - Campo Grande, <br>Recife - PE, 52031-050</p>
                        </div>
                        <p>
                            <img src="@/assets/img/icon/icon-19.svg" alt="" class="img-fluid">
                            contato@caduceus.com.br
                        </p>
                        <p class="mb-0">
                            <img src="@/assets/img/icon/icon-21.svg" alt="" class="img-fluid">
                            +55 81 98162-3838
                        </p>
                    </div>
                </div>
                <!-- /Footer Widget -->
                
            </div>
            
        </div>
    </div>
</div>
<!-- /Footer Top -->
</template>