<template>
    <layouts-instructorborder></layouts-instructorborder>

    <student-breadcrumb :title="title" :text="text" :text1="text1" />

    <!-- Page Content -->
    <div class="page-content">
        <div class="container">
            <div class="row">

                <!-- sidebar -->
                <instructor-sidebar></instructor-sidebar>
                <!-- /Sidebar -->

                <!-- Student Dashboard -->
                <instructor-dashboard v-if="isInstructor"></instructor-dashboard>
                <student-dashboard v-if="isStudent"></student-dashboard>
                <!-- Student Dashboard -->

            </div>
        </div>
    </div>
    <!-- /Page Content -->

    <layouts1></layouts1>
</template>

<script setup>
import { reactive, computed, ref } from 'vue';
import { JWTHandler } from '@/jwtHandler';

// Estado reativo para armazenar títulos e textos

const title = ref("Dashboard");
const text = ref("Home");
const text1 = ref("Dashboard");

// Computados para guardar booleans indicando os papéis do usuário
const isAdmin = computed(() => JWTHandler.hasRole('ADMIN'));
const isStudent = computed(() => JWTHandler.hasRole('STUDENT'));
const isInstructor = computed(() => JWTHandler.hasRole('INSTRUCTOR'));

</script>