<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Order History -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Histórico de Assinaturas</h3>
              </div>
              <div class="checkout-form">
                <!-- Order Tabs -->
               
                <!-- /Order Tabs -->

                <!-- Tab Content -->
                <div class="tab-content">
                  <!-- Today -->
                  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Assinatura</th>
          <th>Data</th>
          <th>Preco</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="subscription in subscriptions" :key="subscription.id">
          <td>{{abreviarUUID(subscription?.id)}}</td>
          <td>
            {{subscription.subscriptionOffer.description}}
          </td>
          <td>{{formatarDataAbreviada(subscription.createdAt)}}</td>
          <td>{{ toCurrency(subscription.subscriptionOffer.value) }}</td>
          <td>{{subscription.status}} <br/>
            {{ subscription.status === 'CANCELLED' && subscription.deletedAt ? formatarDataAbreviada(subscription.deletedAt) : '' }}</td>
          <td v-if="subscription.status !== 'CANCELLED'">
            <a :href="subscription.receiptUrl" target="_blank" class="action-icon" v-if="subscription.receiptUrl"
              ><i class="bx bxs-download"></i
            ></a>
            <a @click="deleteSubscription(subscription)" target="_blank" class="action-icon" v-if="subscription.id"
              ><i class="bx bxs-trash"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
                  <!-- /Yearly -->
                </div>
                <!-- /Tab Content -->
              </div>
            </div>
          </div>

          <div class="dash-pagination" v-if="false">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Page 1 of 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Order History -->
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>
<script setup>
import Swal from "sweetalert2";
import ApiService from '@/services/ApiService';
import axiosInstance, { tratarError } from "@/axiosConfig";
import { abreviarUUID, formatarDataAbreviada, toCurrency } from "@/utils";
import {onMounted, ref} from "vue";
const title = ref("Minhas Assinaturas");
const text = ref("Home");
const text1 = ref("Minhas Assinaturas");
const subscriptions = ref([]);

onMounted(async () => {
  loadData();
})
const loadData = async () => {
  try {
    const response = await ApiService.get('/subscriptions');
    subscriptions.value = response.data;
  } catch (error) {
    tratarError(error);
  } finally {
  }
}
const deleteSubscription = async(subscription) => {


  try {
    const response = await ApiService.delete('/subscriptions/'+subscription.id);
    Swal.fire({
      text: "Assinatura removida com sucesso",
      icon: "success",
      confirmButtonText: "Ok",
    });
    loadData();
  } catch (error) {
    tratarError(error);
  } finally {
  }
}
</script>
