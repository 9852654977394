<template>
  <div>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Quiz -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Meus Alunos</h3>
              </div>
              <div class="checkout-form">
                <div class="announcement-filter-head">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Curso</label>
                        <vue-select
                          :options="courses"
                          id="uisel"
                          placeholder="Selecione"
                          @select="selectStudents"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Status</label>
                        <vue-select
                          :options="['Todos', 'Pendente', 'Ativa', 'Cancelada']"
                          id="oldsel"
                          placeholder="Default"
                          v-model="enrollmentStatusSelected"
                          @select="filterStatus()"
                        />
                      </div>
                    </div>
         
                  </div>
                </div>
                <div class="table-responsive custom-table" v-if="currentEnrollmentsFiltered && currentEnrollmentsFiltered.length > 0">
                  <!-- Referred Users-->
                  <table class="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>Aluno</th>
                        <th>Status Matrícula</th>
                        <th>Status Pagamento</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="e in currentEnrollmentsFiltered" :key="e.id">
                        <td>
                          <div>
                            {{e.fullName}} <br/>
                            <small>{{ e.email }}<br/>
                              {{ e.mobilePhone }}</small>
                          </div>
                        </td>
                        <td>
                          <div class="announcement-table-data">
                            {{getStatusEnrollment(e.enrollmentStatus)}}
                          </div>
                        </td>
                        <td>
                          <div class="announcement-table-data">
                            {{getStatusPayment(e.paymentStatus)}} ({{ e.paymentMethod }})
                          </div>
                        </td>
                        
                        <td>
                          <a @click="downloadCertificate(e.enrollmentId)" class="d-flex align-items-center tab-title"><img src="@/assets/img/icon/certificate.svg" width="20px" alt="" v-if="e.enrollmentStatus === 'ACTIVE'"/></a>
                        </td>
                        <td>
                          <!-- <a href="#" class="d-flex align-items-center tab-title"><i class="bx bx-edit me-2"></i>Edit</a> -->
                        </td>
                        <td>
                          <!-- <a href="#" class="d-flex align-items-center tab-title"><i class="bx bx-trash me-2"></i>Delete</a> -->
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
                  <div class="row" v-else>
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Nenhum registro encontrado com a situação <b>{{ enrollmentStatusSelected }}</b></label>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>

          <div class="dash-pagination" v-if="false">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Page 1 of 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Quiz -->
      </div>
    </div>
  </div>
</div>
</template>
<script setup>
import ApiService from '@/services/ApiService';
import { getStatusEnrollment, getStatusPayment } from '@/utils';
import { onMounted, ref } from 'vue';


const title = ref( "Meus Alunos");
const text = ref( "Home");
const text1 = ref( "Announcements");
const UISel = ref( ["All", "Javascript Course", "Fullstack Web Developer"]);
const OldSel = ref( ["Nome"]);
const UnSel = ref( ["Paid", "Unpaid"]);

// Lista de cursos resumidos
const courseSummaries = ref([]);
const courses = ref([])
const currentEnrollments = ref([])
const currentEnrollmentsFiltered = ref([])
// Função para buscar os cursos resumidos
const fetchCourseSummaries = async () => {
  try {
    const response = await ApiService.get('/courses/me');
    courseSummaries.value = response.data;
    // courses.value.push({id: "", desc})
    courseSummaries.value.map(c => courses.value.push({ id: c.id, text: c.title }))
  } catch (error) {
    console.error("Erro ao buscar os cursos resumidos:", error);
  }
};
const selectStudents = async (course) => {
  try {
    const response = await ApiService.get(`/courses/${course.id}/enrollments`);
    currentEnrollments.value = response.data;
    currentEnrollmentsFiltered.value = response.data;
    filterStatus();
    // courses.value.push({id: "", desc})
  } catch (error) {
    console.error("Erro ao buscar os cursos resumidos:", error);
  }
  
}
const enrollmentStatusSelected = ref('Ativa');
const filterStatus = async () => {
  if(enrollmentStatusSelected.value === 'Todos') {
    currentEnrollmentsFiltered.value = currentEnrollments.value
  } else {
    currentEnrollmentsFiltered.value = currentEnrollments.value.filter(c=>getStatusEnrollment(c.enrollmentStatus) === enrollmentStatusSelected.value)
  }
  
}

const downloadCertificate = async (id) => {
  try {
    const response = await ApiService.get(`/enrollments/certificate/${id}`);
  window.open(response.data, '_blank');

  } catch (error) {
    // tratarError(error);
    console.error('Erro no pagamento:', error);
  }
};
const iniciarDownload = async (url)=> {
      try {
        // Faz a requisição para obter o arquivo como um Blob
        const response = await fetch(url);
        const blob = await response.blob();

        // Cria um link temporário
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        // Define o nome do arquivo para o download
        link.setAttribute('download', 'arquivo.pdf'); // Altere o nome do arquivo se necessário

        // Adiciona o link ao DOM e clica nele
        document.body.appendChild(link);
        link.click();

        // Remove o link do DOM após o download
        document.body.removeChild(link);

        // Libera o objeto URL criado
        URL.revokeObjectURL(link.href);
      } catch (error) {
        console.error('Erro ao baixar o arquivo:', error);
      }
    }
onMounted(() => {
  fetchCourseSummaries();
});
</script>
