<template>
  
  <ul class="nav header-navbar-rht">
    <div class="transform-button-three" v-if="!isSubscriber" style="padding-right: 30px;">
      <button @click="checkoutSubscription()" class="btn btn-action"
        >Seja Assinante</button
      >
    </div>
    <li class="nav-item user-nav">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>
    <li class="nav-item user-nav" v-if="isUsuarioLogado">
      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
        <span class="user-img">
          <img :src="userData?.profileImageUrl" alt="Img" v-if="userData?.profileImageUrl">
          <img src="@/assets/img/user/user-17.jpg" alt="Img" v-else>
          <span class="status online"></span>
        </span>
      </a>
      <div class="users dropdown-menu dropdown-menu-right" data-popper-placement="bottom-end">
        <div class="user-header">
          <div class="avatar avatar-sm">
            <img :src="userData?.profileImageUrl" alt="User Image" class="avatar-img rounded-circle" v-if="userData?.profileImageUrl">
            <img src="@/assets/img/user/user-17.jpg" alt="User Image" class="avatar-img rounded-circle" v-else>
          </div>
          <div class="user-text">
            <h6>{{userData.fullName}}</h6>
            <p class="text-muted mb-0">{{userData?.role}}</p>
          </div>
        </div>
        <router-link class="dropdown-item" to="/caduceus/dashboard"><i class="feather-home me-1"></i> Dashboard</router-link>
        <router-link class="dropdown-item" to="/caduceus/settings"><i class="feather-user me-1"></i>  Perfil</router-link>
        <!-- <div class="dropdown-item night-mode">
          <span><i class="feather-moon me-1"></i> Night Mode </span>
          <div class="form-check form-switch check-on m-0">
            <input class="form-check-input" type="checkbox" id="night-mode">
          </div>
        </div> -->
        <a class="dropdown-item" @click="logout()"><i class="feather-log-out me-1"></i> Sair</a>
      </div>
    </li>
  </ul>
</template>
<script setup>
import { JWTHandler } from "@/jwtHandler";
import { ref, onMounted } from "vue";
import ApiService from '@/services/ApiService';
import { useRoute, useRouter } from "vue-router";
const router = useRouter();

// Dados reativos
const userData = ref(JWTHandler.getUserData());
const isUsuarioLogado = ref(JWTHandler.isCurrentTokenValid());
const darkModeToggle = ref(null);
const lightModeToggle = ref(null);
const isSubscriber = ref(false);
const isAuthenticated = ref(JWTHandler.isCurrentTokenValid());

// Funções para alternar modo escuro
const enableDarkMode = () => {
  document.documentElement.setAttribute("class", "light dark");
  darkModeToggle.value.classList.remove("activate");
  lightModeToggle.value.classList.add("activate");
  localStorage.setItem("darkMode", "enabled");
};

const disableDarkMode = () => {
  document.documentElement.setAttribute("class", "light");
  lightModeToggle.value.classList.remove("activate");
  darkModeToggle.value.classList.add("activate");
  localStorage.removeItem("darkMode");
};

// Verifica o modo atual ao carregar a página
onMounted(async () => {

  if(JWTHandler.isCurrentTokenValid()) {
      const isSubscriberResponse = await ApiService.get(`/subscriptions/isSubscriber`);
      isSubscriber.value = isSubscriberResponse.data;
    }
    
  const darkMode = localStorage.getItem("darkMode");
  if (darkMode === "enabled") {
    enableDarkMode();
  } else {
    disableDarkMode();
  }
});
const checkoutSubscription = async () => {
  if(!isAuthenticated.value) {
    router.push('/login');
  } else {
    router.push('/pages/checkout-subscription');
  }
}

const logout = () => {
  JWTHandler.logout();
  window.location.href = "/";
}
</script>
