<template>
  <div class="messages">
    <div class="chats">
      <div class="chat-avatar">
        <img
          src="@/assets/img/user/user12.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
      <div class="chat-content">
        <div class="chat-profile-name">
          <h6>
            Mark Villiams<span>8:16 PM</span
            ><span class="check-star msg-star d-none"><i class="bx bxs-star"></i></span>
          </h6>
          <div class="chat-action-btns ms-2">
            <div class="chat-action-col">
              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa-solid fa-ellipsis"></i>
              </a>
              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                <a href="javascript:void(0);" class="dropdown-item message-info-left"
                  ><span><i class="bx bx-info-circle"></i></span>Message Info
                </a>
                <a href="javascript:void(0);" class="dropdown-item reply-button"
                  ><span><i class="bx bx-share"></i></span>Reply</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-smile"></i></span>React</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-reply"></i></span>Forward</a
                >
                <a href="javascript:void(0);" class="dropdown-item click-star"
                  ><span><i class="bx bx-star"></i></span
                  ><span class="star-msg">Star Message</span></a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-dislike"></i></span>Report</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-trash"></i></span>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="message-content">
          Hello <a href="javascript:void(0);">@Alex</a> Thank you for the beautiful web
          design ahead schedule.
        </div>
      </div>
    </div>
    <div class="chat-line">
      <span class="chat-date">Today, July 24</span>
    </div>
    <div class="chats chats-right">
      <div class="chat-content">
        <div class="chat-profile-name text-end">
          <h6>
            Alex Smith<span>8:16 PM</span
            ><span class="check-star msg-star-one d-none"
              ><i class="bx bxs-star"></i
            ></span>
          </h6>
          <div class="chat-action-btns ms-2">
            <div class="chat-action-col">
              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa-solid fa-ellipsis"></i>
              </a>
              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                <a href="javascript:void(0);" class="dropdown-item message-info-left"
                  ><span><i class="bx bx-info-circle"></i></span>Message Info
                </a>
                <a href="javascript:void(0);" class="dropdown-item reply-button"
                  ><span><i class="bx bx-share"></i></span>Reply</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-smile"></i></span>React</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-reply"></i></span>Forward</a
                >
                <a href="javascript:void(0);" class="dropdown-item click-star-one"
                  ><span><i class="bx bx-star"></i></span
                  ><span class="star-msg-one">Star Message</span></a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-edit-alt"></i></span>Edit</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-trash"></i></span>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="message-content">
          <div class="chat-voice-group">
            <ul>
              <li>
                <a href="javascript:void(0);"
                  ><span><img src="@/assets/img/icon/play-01.svg" alt="image" /></span
                ></a>
              </li>
              <li>
                <img src="@/assets/img/icon/voice.svg" class="img-fluid" alt="image" />
              </li>
              <li>0:05</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="chat-avatar">
        <img
          src="@/assets/img/user/user2.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
    </div>
    <div class="chats">
      <div class="chat-avatar">
        <img
          src="@/assets/img/user/user12.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
      <div class="chat-content">
        <div class="chat-profile-name">
          <h6>
            Mark Villiams<span>8:16 PM</span
            ><span class="check-star msg-star-three d-none"
              ><i class="bx bxs-star"></i
            ></span>
          </h6>
          <div class="chat-action-btns ms-2">
            <div class="chat-action-col">
              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa-solid fa-ellipsis"></i>
              </a>
              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                <a href="javascript:void(0);" class="dropdown-item message-info-left"
                  ><span><i class="bx bx-info-circle"></i></span>Message Info
                </a>
                <a href="javascript:void(0);" class="dropdown-item reply-button"
                  ><span><i class="bx bx-share"></i></span>Reply</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-smile"></i></span>React</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-reply"></i></span>Forward</a
                >
                <a href="javascript:void(0);" class="dropdown-item click-star-three"
                  ><span><i class="bx bx-star"></i></span
                  ><span class="star-msg-three">Star Message</span></a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-dislike"></i></span>Report</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-trash"></i></span>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="message-content award-link chat-award-link">
          <a href="javascript:void(0);">https://www.youtube.com/watch?v=GCmL3mS0Psk</a>
          <img src="@/assets/img/chat-img-01.jpg" class="img-fluid" alt="img" />
        </div>
      </div>
    </div>
    <div class="chats chats-right">
      <div class="chat-content">
        <div class="chat-profile-name text-end">
          <h6>
            Alex Smith<span>8:16 PM</span
            ><span class="check-star msg-star-one d-none"
              ><i class="bx bxs-star"></i
            ></span>
          </h6>
          <div class="chat-action-btns ms-2">
            <div class="chat-action-col">
              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa-solid fa-ellipsis"></i>
              </a>
              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                <a href="javascript:void(0);" class="dropdown-item message-info-left"
                  ><span><i class="bx bx-info-circle"></i></span>Message Info
                </a>
                <a href="javascript:void(0);" class="dropdown-item reply-button"
                  ><span><i class="bx bx-share"></i></span>Reply</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-smile"></i></span>React</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-reply"></i></span>Forward</a
                >
                <a href="javascript:void(0);" class="dropdown-item click-star-one"
                  ><span><i class="bx bx-star"></i></span
                  ><span class="star-msg-one">Star Message</span></a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-edit-alt"></i></span>Edit</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-trash"></i></span>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="message-content">
          Thankyou for the notes sir. Will you send me the location where I could bought?
        </div>
      </div>
      <div class="chat-avatar">
        <img
          src="@/assets/img/user/user2.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
    </div>

    <div class="chats">
      <div class="chat-avatar">
        <img
          src="@/assets/img/user/user12.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
      <div class="chat-content">
        <div class="chat-profile-name">
          <h6>
            Mark Villiams<span>8:16 PM</span
            ><span class="check-star msg-star-five d-none"
              ><i class="bx bxs-star"></i
            ></span>
          </h6>
          <div class="chat-action-btns ms-2">
            <div class="chat-action-col">
              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa-solid fa-ellipsis"></i>
              </a>
              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                <a href="javascript:void(0);" class="dropdown-item message-info-left"
                  ><span><i class="bx bx-info-circle"></i></span>Message Info
                </a>
                <a href="javascript:void(0);" class="dropdown-item reply-button"
                  ><span><i class="bx bx-share"></i></span>Reply</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-smile"></i></span>React</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-reply"></i></span>Forward</a
                >
                <a href="javascript:void(0);" class="dropdown-item click-star-five"
                  ><span><i class="bx bx-star"></i></span
                  ><span class="star-msg-five">Star Message</span></a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-dislike"></i></span>Report</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-trash"></i></span>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="message-content">
          <div class="location-sharing">
            <div class="sharing-location-icon">
              <i class="fa-solid fa-location-dot"></i>
            </div>
            <h6>My Location <a href="#">Download</a></h6>
          </div>
        </div>
        <div class="like-chat-grp">
          <ul>
            <li class="like-chat">
              <a href="javascript:void(0);"
                >2<img src="@/assets/img/icon/like.svg" alt="Icon"
              /></a>
            </li>
            <li class="comment-chat">
              <a href="javascript:void(0);"
                >2<img src="@/assets/img/icon/heart.svg" alt="Icon"
              /></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="chats">
      <div class="chat-avatar">
        <img
          src="@/assets/img/user/user12.jpg"
          class="rounded-circle dreams_chat"
          alt="image"
        />
      </div>
      <div class="chat-content">
        <div class="chat-profile-name">
          <h6>
            Mark Villiams<span>8:16 PM</span
            ><span class="check-star msg-star d-none"><i class="bx bxs-star"></i></span>
          </h6>
          <div class="chat-action-btns ms-2">
            <div class="chat-action-col">
              <a class="#" href="javascript:void(0);" data-bs-toggle="dropdown">
                <i class="fa-solid fa-ellipsis"></i>
              </a>
              <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
                <a href="javascript:void(0);" class="dropdown-item message-info-left"
                  ><span><i class="bx bx-info-circle"></i></span>Message Info
                </a>
                <a href="javascript:void(0);" class="dropdown-item reply-button"
                  ><span><i class="bx bx-share"></i></span>Reply</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-smile"></i></span>React</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-reply"></i></span>Forward</a
                >
                <a href="javascript:void(0);" class="dropdown-item click-star"
                  ><span><i class="bx bx-star"></i></span
                  ><span class="star-msg">Star Message</span></a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-edit-alt"></i></span>Report</a
                >
                <a href="javascript:void(0);" class="dropdown-item"
                  ><span><i class="bx bx-trash"></i></span>Delete</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="message-content reply-getcontent">Thank you for your support</div>
      </div>
    </div>
  </div>
</template>
