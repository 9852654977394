<template>
  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>Ticket ID</th>
          <th>Date</th>
          <th>Subject</th>
          <th>Priority</th>
          <th>Category</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ticket#002</td>
          <td>March 18, 2024</td>
          <td>I have a problem</td>
          <td>
            <span class="resut-badge badge-light-danger">High</span>
          </td>
          <td>Language Issues</td>
          <td>
            <span class="status-badge badge-soft-warning">Inprogress</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#006</td>
          <td>April 28, 2024</td>
          <td>When will start the order</td>
          <td>
            <span class="resut-badge badge-light-danger">High</span>
          </td>
          <td>Demo Problem</td>
          <td>
            <span class="status-badge badge-soft-warning">Inprogress</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
