<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Configurações -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <instructor-settings-sidebar></instructor-settings-sidebar>
              <form action="/instructor-settings">
                <div class="course-group profile-upload-group mb-0 d-flex">
                  <div
                    class="course-group-img profile-edit-field d-flex align-items-center"
                  >
                    <router-link to="/caduceus/profile" class="profile-pic"
                      ><img v-if="!profileImageUrl"
                        src="@/assets/img/user/user-17.jpg"
                        alt="Img"
                        class="img-fluid"
                    />
                    <img v-if="profileImageUrl"
                        :src="profileImageUrl"
                        alt="Img"
                        class="img-fluid"
                    />
                  </router-link>
                    <div class="profile-upload-head">
                      <h4>
                        <router-link to="/caduceus/instructor-profile"
                          >Sua imagem de perfil</router-link
                        >
                      </h4>
                      <p>PNG ou JPG com no máximo 800px de largura e altura</p>
                      <div class="new-employee-field">
                        <div class="d-flex align-items-center mt-2">
                          <div class="image-upload mb-0">
                            <input
      id="fileInput"
      type="file"
      class="form-control"
      @change="handleFileChange"
    />
                            <div class="image-uploads">
                              <i class="bx bx-cloud-upload"></i>
                            </div>
                          </div>
                          <div class="img-delete">
                            <a href="#" class="delete-icon"
                              ><i class="bx bx-trash"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-form settings-wrap">
                  <div class="edit-profile-info">
                    <h5>Dados Pessoais</h5>
                    <p>Edite seus dados pessoais</p>
                  </div>
                  <div class="row">
    <div class="col-md-6">
      <div class="input-block">
        <label class="form-label">Nome</label>
        <input
          type="text"
          class="form-control"
          v-model="fullName"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-block">
        <label class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          v-model="email"
        />
      </div>
    </div>
    <div class="col-md-6">
      <div class="input-block">
        <label class="form-label">Telefone</label>
        <input
          type="text"
          v-mask="'###########'"
          class="form-control"
          v-model="mobilePhone"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="input-block">
        <label class="form-label">Ocupação</label>
        <input
          type="text"
          class="form-control"
          v-model="designation"
        />
      </div>
    </div>
    <div class="col-md-12">
      <div class="input-block">
        <label class="form-label">Sobre</label>
        <div id="editor">
                            <ckeditor
                              :editor="editor"
                              v-model="bio"
                              :config="editorConfig"
                            ></ckeditor>
                          </div>
      </div>
    </div>
    <div class="col-md-12">
      <button
        class="btn btn-primary"
        type="button"
        @click="updateUser"
      >
        Atualizar Perfil
      </button>
    </div>

    <!-- Mensagens de erro ou sucesso -->
    <div class="col-md-12" v-if="errorMessage">
      <p class="text-danger">{{ errorMessage }}</p>
    </div>
    <div class="col-md-12" v-if="successMessage">
      <p class="text-success">{{ successMessage }}</p>
    </div>
  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Instructor Configurações -->
      </div>
    </div>
  </div>
  <layouts1></layouts1>
</template>
<script setup>
import { ref } from "vue";
import axios from 'axios'
import ApiService from '@/services/ApiService';
import axiosInstance from "@/axiosConfig";
import { JWTHandler } from "@/jwtHandler";
import { useToast } from "vue-toast-notification";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const editor = ClassicEditor;
// Estados reativos para os campos do formulário
const userId = JWTHandler.getUserData().id // Substitua pelo ID real do usuário
const fullName = ref('')

const email = ref('')

const username = ref('')
const profileImageUrl = ref('')
const mobilePhone = ref('')
const designation = ref('')
const bio = ref('')
const errorMessage = ref('')
const successMessage = ref('')

// Função para carregar os dados iniciais
const loadUserData = async () => {
  try {
    const response = await ApiService.get(`/users/me`)
    const user = response.data
    fullName.value = user.fullName
    email.value = user.email
    username.value = user.username
    mobilePhone.value = user.mobilePhone
    designation.value = user.designation
    bio.value = user.bio ? user.bio : ''
    profileImageUrl.value = user.profileImageUrl
  } catch (error) {
    errorMessage.value = 'Erro ao carregar os dados do usuário.'
  }
}
const editorConfig = {
        // Configuração adicional para ajustar altura
        // toolbar: [
        //   "heading",
        //   "|",
        //   "bold",
        //   "italic",
        //   "link",
        //   "bulletedList",
        //   "numberedList",
        //   "|",
        //   "undo",
        //   "redo",
        // ],
        height: 1000, // Ajusta a altura do editor
      };
// Função para atualizar o perfil
const updateUser = async () => {
  try {
    const payload = {
      fullName: fullName.value,
      username: username.value,
      mobilePhone: mobilePhone.value,
      designation: designation.value,
      bio: bio.value,
      profileImageUrl: profileImageUrl.value
    }
    await ApiService.put(`/users/${userId}`, payload)
    useToast().success('Perfil atualizado com sucesso!');
    
    // successMessage.value = 'Perfil atualizado com sucesso!'
    // errorMessage.value = ''
  } catch (error) {
    if (error.response && error.response.status === 403) {
      useToast().error(error.response.data.message);
    } else {
      useToast().error('Erro ao atualizar o perfil. Verifique os dados e tente novamente.')
    }
    
    // errorMessage.value = 'Erro ao atualizar o perfil. Verifique os dados e tente novamente.'
    // successMessage.value = ''
  }
}
const selectedFile = ref(null)
const url_file = ref(null);
const handleFileChange = async (event) => {
  const file = event.target.files[0]
  if (file) {
    selectedFile.value = file
    await uploadFile(file)
  }
}

// Método para enviar o arquivo para o backend
const uploadFile = async (file) => {
  try {
    const formData = new FormData()
    formData.append('file', file) // Adiciona o arquivo ao FormData

    // Faz a requisição POST para o backend
    const response = await ApiService.post('/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Header necessário para upload de arquivos
      },
    })
    profileImageUrl.value = response.data.url
    console.log('Upload realizado com sucesso:', response.data)
  } catch (error) {
    console.error('Erro ao fazer upload:', error)
  }
}
// Carregar dados do usuário na montagem
loadUserData()
const title = ref("Configurações");
const text = ref("Home");
const text1 = ref("Editar Perfil");
</script>


<style scoped>
::v-deep(.ck-editor__editable_inline) {
  min-height: 500px !important;
  max-height: 800px !important;
  overflow-y: auto;
}
</style>