<template>
  <div class="menu-header">
    <router-link to="/home/" class="menu-logo">
      <img src="@/assets/img/caduceus.png" class="img-fluid" alt="Logo" />
    </router-link>
    <a id="menu_close" class="menu-close" href="javascript:void(0);">
      <i class="fas fa-times"></i>
    </a>
  </div>
</template>
