<template>
  <div class="home-three">
      <layouts-index></layouts-index>
      <home-threebanner :categories="categories"></home-threebanner>
      <favourite-three :categories="categories"></favourite-three>
      <!-- <acheive-goals></acheive-goals>
      <instructor-three></instructor-three> -->
      <footer-three></footer-three>
      <Loading
            v-model:active="loadingStore.isLoading"
            :can-cancel="false"
            :is-full-page="true"
          />

  </div>
</template>
<script setup>
import ApiService from '@/services/ApiService';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useLoadinStore } from '@/stores/loadingStore';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
const loadingStore = storeToRefs(useLoadinStore());

// Nome do componente (opcional, mas pode ser útil)
const name = "index-three";
const categories = ref([]);
onMounted(async () => {
  try {
    const responseCategories = await ApiService.get('/categories');
    categories.value = responseCategories.data;
    categories.value.map(c => c['text'] = c.description)
    
  } catch (error) {
    
  } finally {
  }
    

});
</script>
