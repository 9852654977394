<template>
  <div>
  <!-- Home Banner -->
  <section class="home-three-slide d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-8 col-md-12 col-12" data-aos="fade-down">
          <div class="home-three-slide-face">
            <div class="home-three-slide-text">
              <h5> <a href="https://www.c-online.med.br/caduceus/" style="color: white;"> Conheça o Instituto Caduceus</a></h5>
              <h5 style="background: red; margin-left: 4px;"><a href="https://www.c-online.med.br/circor/" style="color: white;">Participe e Ajude o Círculo do Coração</a></h5>
              <br/>
              <template v-for="c in categories" >
                <h5 style="margin-right: 4px;" @click="scrollToSection(c.id)">{{ c.text }}</h5>
                
              </template>
<h4>Aprenda com <b>experts em cardiologia e<br/> saúde multidisciplinar</b> no <span style="color: green">Instituto Caduceus</span></h4>
<p>
Modelos Online, presencial ou semi-presencial.<br/>Tudo para impulsionar seu conhecimento e prática clínica.
</p>

            </div>
            <div class="banner-three-content" v-if="false">
              <form class="form" @submit.prevent="submitForm">
                <div class="form-inner-three">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Procurar curso"
                    />
                    <span class="drop-detail-three">
                      <vue-select :options="categories" :optionValue="'id'" :optionLabel="'description'" placeholder="Selecione" />
                    </span>
                      <button class="btn btn-three-primary sub-btn" type="submit">
                        <i class="fas fa-arrow-right"></i>
                      </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-4 col-md-6 col-12" data-aos="fade-up">
          <div class="girl-slide-img aos">
            <img class="img-fluid" src="@/assets/img/aulascadu.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Home Banner -->

  <!--Online Courses -->
  <section class="section student-course home-three-course">
    <div class="container">
      <div class="course-widget-three">
        <div class="row">
          <div class="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
            <div class="course-details-three">
              <div class="align-items-center">
                <div class="course-count-three course-count ms-0">
                  <div class="course-img">
                    <img
                      class="img-fluid"
                      src="@/assets/img/icon-three/course-01A.svg"
                      alt=""
                    />
                  </div>
                  <div class="course-content-three">
                    <h4 class="text-green">
                      <span class="counterUp"
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="1"
                          :delay="3"
                          :endAmount="10"
                          :duration="2"
                          :autoinit="true" /></span
                      >+
                    </h4>
                    <p>Cursos Online</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
            <div class="course-details-three">
              <div class="align-items-center">
                <div class="course-count-three course-count ms-0">
                  <div class="course-img">
                    <img
                      class="img-fluid"
                      src="@/assets/img/icon-three/course-02B.svg"
                      alt=""
                    />
                  </div>
                  <div class="course-content-three">
                    <h4 class="text-green">
                      <span class="counterUp"
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="1"
                          :delay="3"
                          :endAmount="10"
                          :duration="2"
                          :autoinit="true" /></span
                      >+
                    </h4>
                    <p>Instrutores</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
            <div class="course-details-three">
              <div class="align-items-center">
                <div class="course-count-three course-count ms-0">
                  <div class="course-img">
                    <img
                      class="img-fluid"
                      src="@/assets/img/icon-three/course-03C.svg"
                      alt=""
                    />
                  </div>
                  <div class="course-content-three">
                    <h4 class="text-green">
                      <span class="counterUp"
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="1"
                          :delay="3"
                          :endAmount="10"
                          :duration="5"
                          :autoinit="true" /></span
                      >K+
                    </h4>
                    <p>Imagens de ECO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
            <div class="course-details-three mb-0">
              <div class="align-items-center">
                <div class="course-count-three">
                  <div class="course-img">
                    <img
                      class="img-fluid"
                      src="@/assets/img/icon-three/course-04D.svg"
                      alt=""
                    />
                  </div>
                  <div class="course-content-three course-count ms-0">
                    <h4 class="text-green">
                      <span class="counterUp"
                        ><vue3-autocounter
                          class="counter"
                          ref="counter"
                          :startAmount="1"
                          :delay="3"
                          :endAmount="1000"
                          :duration="2"
                          :autoinit="true" /></span
                      >+
                    </h4>
                    <p>Estudantes</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Online Courses -->

  <!-- Master skills Career -->
  <section class="master-skill-three">
    <!-- <div class="master-three-vector">
      <img class="ellipse-right img-fluid" src="@/assets/img/bg/pattern-01.png" alt="" />
    </div> -->
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12" data-aos="fade-right">
          <div class="master-three-images">
            <div class="master-three-left">
              <img
                class="img-fluid"
                style="border-top-left-radius: 50px; border-bottom-right-radius: 50px;"
                src="@/assets/img/cadu.jpeg"
                alt="image-banner"
                title="image-banner"
              />
            </div>
          </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12" data-aos="fade-left">
          <div class="home-three-head" data-aos="fade-up">
            <h2>Flexibilidade para estudar no seu tempo. Conheça nossos cursos
            </h2>
          </div>
          <div class="home-three-content" data-aos="fade-up" v-if="false">
            <p>
              Get certified, master modern tech skills, and level up your career whether
              you’re starting out or a seasoned pro. 95% of eLearning learners report our
              hands-on content directly helped their careers.
            </p>
          </div>
          <div class="skils-group">
            <div class="row">
              <div
                v-for="(item, index) in skillsthree"
                :key="index"
                class="col-lg-6 col-xs-12 col-sm-6"
                :data-aos="item.animation"
              >
                <div class="skils-icon-item">
                  <div class="skils-icon">
                    <img
                      
                      class="img-fluid"
                      :src="require(`@/assets/img/icon-three/${item.iconSrc}`)"
                      :alt="item.altText"
                    />
                  </div>
                  <div class="skils-content">
                    <p class="mb-0">{{ item.content }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
  <!-- /Master skills Career -->
</template>
<script setup>
import { ref, onMounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
import skillsthree from '@/assets/json/skillsthree.json';
import { useRouter } from 'vue-router';
import { eventBus } from '@/stores/eventBus';

// Dados reativos
// const Category = ref(['Select category', 'Cardiologia Pediátrica', 'Cardiologia Fetal', 'Ecocardiograma', 'Eletrocardiograma']);
const skillsthreeData = ref(skillsthree);

// Método para submissão do formulário
const submitForm = (values) => {
  console.log(values);
  // Use useRouter para navegação
  useRouter().push('/course/course-list');
};
const scrollToSection = (sectionId) => {
  eventBus.emit("scrollTo", sectionId);
};
// Inicialização do AOS após a montagem do componente
onMounted(() => {
  AOS.init();
});

defineProps({
  categories: {
    type: Array,
    required: true
  }
});
</script>
