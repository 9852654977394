<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->

        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Q & A -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Question & Answer</h3>
              </div>
              <div class="checkout-form">
                <div class="table-responsive custom-table">
                  <!-- Referred Users-->
                  <table class="table table-nowrap mb-0">
                    <thead>
                      <tr>
                        <th>Question No</th>
                        <th>Question</th>
                        <th>Course</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th>Posted By</th>
                        <th>No of Replies</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Q1</td>
                        <td>
                          <span class="title-course"
                            >AngularJS and Angular refer to the same
                            framework.</span
                          >
                        </td>
                        <td>
                          <router-link to="/caduceus/course/course-details" class="tab-title title-course"
                            >Learn Angular Fundamentals Beginners Guide</router-link
                          >
                        </td>
                        <td>
                          <i class="bx bxs-adjust"></i>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title="True / False"
                            ><i class="bx bxs-info-circle"></i
                          ></a>
                        </td>
                        <td>
                          March 24, 2024 <span class="d-block">09:30 AM</span>
                        </td>
                        <td>
                          <h2 class="table-avatar d-flex align-items-center">
                            <a href="javascript:void(0);" class="avatar">
                              <img
                                class="avatar-img"
                                src="@/assets/img/user/user2.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a href="javascript:void(0);">Thompson Hicks</a>
                          </h2>
                        </td>
                        <td>0</td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-undo"></i>Reply</a>
                          </div>
                        </td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-show"></i>View</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Q2</td>
                        <td>
                          <span class="title-course"
                            >What is the purpose of Angular's NgIf
                            directive?</span
                          >
                        </td>
                        <td>
                          <router-link to="/caduceus/course/course-details"
                            >Learn Angular Fundamentals Beginners Guide</router-link
                          >
                        </td>
                        <td>
                          <i class="bx bx-check-double"></i>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title="Multiple Choice"
                            ><i class="bx bxs-info-circle"></i
                          ></a>
                        </td>
                        <td>
                          March 24, 2024 <span class="d-block">09:30 AM</span>
                        </td>
                        <td>
                          <h2 class="table-avatar d-flex align-items-center">
                            <a href="javascript:void(0);" class="avatar">
                              <img
                                class="avatar-img"
                                src="@/assets/img/user/user3.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a href="javascript:void(0);">James Schulte</a>
                          </h2>
                        </td>
                        <td>0</td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-undo"></i>Reply</a>
                          </div>
                        </td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-show"></i>View</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Q3</td>
                        <td>
                          <span class="title-course"
                            >Observables are not part of Angular's HTTP
                            module.</span
                          >
                        </td>
                        <td>
                          <router-link to="/caduceus/course/course-details"
                            >Learn Angular Fundamentals Beginners Guide</router-link
                          >
                        </td>
                        <td>
                          <i class="bx bxs-adjust"></i>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title="True / False"
                            ><i class="bx bxs-info-circle"></i
                          ></a>
                        </td>
                        <td>
                          March 24, 2024 <span class="d-block">09:30 AM</span>
                        </td>
                        <td>
                          <h2 class="table-avatar d-flex align-items-center">
                            <a href="javascript:void(0);" class="avatar">
                              <img
                                class="avatar-img"
                                src="@/assets/img/user/user5.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a href="javascript:void(0);">Doris Hughes</a>
                          </h2>
                        </td>
                        <td>0</td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-undo"></i>Reply</a>
                          </div>
                        </td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-show"></i>View</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Q4</td>
                        <td>
                          <span class="title-course"
                            >Which of the following is a valid way to bind data
                            in Angular?</span
                          >
                        </td>
                        <td>
                          <router-link to="/caduceus/course/course-details"
                            >Learn Angular Fundamentals Beginners Guide</router-link
                          >
                        </td>
                        <td>
                          <i class="bx bx-check-double"></i>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title="Multiple Choice"
                            ><i class="bx bxs-info-circle"></i
                          ></a>
                        </td>
                        <td>
                          March 24, 2024 <span class="d-block">09:30 AM</span>
                        </td>
                        <td>
                          <h2 class="table-avatar d-flex align-items-center">
                            <a href="javascript:void(0);" class="avatar">
                              <img
                                class="avatar-img"
                                src="@/assets/img/user/user13.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a href="javascript:void(0);">Arthur Nalley</a>
                          </h2>
                        </td>
                        <td>0</td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-undo"></i>Reply</a>
                          </div>
                        </td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-show"></i>View</a>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Q5</td>
                        <td>
                          <span class="title-course"
                            >Angular applications can only be written in
                            TypeScript.</span
                          >
                        </td>
                        <td>
                          <router-link to="/caduceus/course/course-details"
                            >Learn Angular Fundamentals Beginners Guide</router-link
                          >
                        </td>
                        <td>
                          <i class="bx bxs-adjust"></i>
                          <a
                            href="#"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-original-title="True / False"
                            ><i class="bx bxs-info-circle"></i
                          ></a>
                        </td>
                        <td>
                          March 24, 2024 <span class="d-block">09:30 AM</span>
                        </td>
                        <td>
                          <h2 class="table-avatar d-flex align-items-center">
                            <a href="javascript:void(0);" class="avatar">
                              <img
                                class="avatar-img"
                                src="@/assets/img/user/user8.jpg"
                                alt="User Image"
                              />
                            </a>
                            <a href="javascript:void(0);">Shirley Lis</a>
                          </h2>
                        </td>
                        <td>0</td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-undo"></i>Reply</a>
                          </div>
                        </td>
                        <td>
                          <div class="table-view">
                            <a href="#"><i class="bx bx-show"></i>View</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="dash-pagination">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Page 1 of 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Q & A -->
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>
<script>
export default {
  data() {
    return {
      title: "Question & Answer",
      text: "Home",
      text1: "Question & Answer",
    };
  },
};
</script>
