<template>
  <ul class="nav header-navbar-rht" v-if="isHometwoRoute">
    <li class="nav-item">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>	
    <li class="nav-item">
      <router-link class="login-head button" to="/login">Login</router-link>
    </li>
    <li class="nav-item">
      <router-link class="signin-head" to="/register">Sign up</router-link>
    </li>
  </ul>
  <ul class="nav header-navbar-rht align-items-center" v-else-if="isHomethreeRoute">
    <li class="nav-item">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>
    <li class="nav-item" v-if="!isAuthenticated">
      <router-link class="nav-link login-three-head button" to="/login"
        ><span>Login</span></router-link
      >
    </li>
    <li class="nav-item" v-if="!isAuthenticated">
      <router-link class="nav-link signin-three-head" to="/register">Criar Conta</router-link>
    </li>
    <div class="transform-button-three" v-if="!isSubscriber" style="padding-right: 30px;">
      <button @click="checkoutSubscription()" class="btn btn-action"
        >Seja Assinante</button
      >
    </div>


            <li class="nav-item user-nav" v-if="isAuthenticated">
              <div>
                <a
                  href="#"
                  id="dark-mode-toggle"
                  ref="darkModeToggle"
                  class="dark-mode-toggle"
                  @click="enableDarkMode"
                >
                  <i class="fa-solid fa-moon"></i>
                </a>
                <a
                  href="#"
                  id="light-mode-toggle"
                  class="dark-mode-toggle"
                  ref="lightModeToggle"
                  @click="disableDarkMode"
                >
                  <i class="fa-solid fa-sun"></i>
                </a>
              </div>
            </li>
            <li class="nav-item user-nav" v-if="isAuthenticated">
              <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
                <span class="user-img">
                  <img :src="userData?.profileImageUrl" alt="Img" v-if="userData?.profileImageUrl"/>
                  <img src="@/assets/img/user/user16.jpg" alt="Img" v-else/>
                  <span class="status online"></span>
                </span>
              </a>
              <div
                class="users dropdown-menu dropdown-menu-right"
                data-popper-placement="bottom-end"
              >
                <div class="user-header">
                  <div class="avatar avatar-sm">
                    <img
                    v-if="!userData?.profileImageUrl"
                      src="@/assets/img/user/user16.jpg"
                      alt="User Image"
                      class="avatar-img rounded-circle"
                    />
                    <img
                      v-else
                      :src="userData?.profileImageUrl"
                      alt="User Image"
                      class="avatar-img rounded-circle"
                    />
                  </div>
                  <div class="user-text">
                    <h6>{{userData.fullName}}</h6>
                    <p class="text-muted mb-0">{{userData.role}}</p>
                  </div>
                </div>
                <router-link
                  class="dropdown-item"
                  to="/caduceus/dashboard"
                  ><i class="feather-home me-1"></i> Dashboard</router-link
                >
                <router-link
                  class="dropdown-item"
                  to="/caduceus/settings"
                  ><i class="feather-user me-1"></i> Perfil</router-link
                >
                <!-- <router-link
                  class="dropdown-item"
                  to="/student/setting-student-subscription"
                  ><i class="feather-star me-1"></i> Subscription</router-link
                >
                <div class="dropdown-item night-mode">
                  <span><i class="feather-moon me-1"></i> Night Modes </span>
                  <div class="form-check form-switch check-on m-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="night-mode"
                    />
                  </div>
                </div> -->
                <a @click="logout" class="dropdown-item"  style="cursor: pointer;"
                  ><i class="feather-log-out me-1"></i> Sair</a
                >
              </div>
            </li>

  </ul>
  
  <ul class="nav header-navbar-rht header-navbar-five" v-else-if="isHomefourRoute">
    <li class="nav-item">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>	
    <li class="nav-item">
      <router-link class="login-five" to="/login">Login</router-link>
    </li>
    <li class="nav-item">
      <router-link class="signup-five" to="/register">Signup</router-link>
    </li>
  </ul>
  <ul class="nav header-navbar-rht" v-else>
    <li class="nav-item">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>
    <li class="nav-item" v-if="!isAuthenticated">
      <router-link class="nav-link header-sign" to="/login">Signin</router-link>
    </li>
    <li class="nav-item" v-if="!isAuthenticated">
      <router-link class="nav-link header-login" to="/register">Signup</router-link>
    </li>
  </ul>
</template>
<script setup>
import { JWTHandler } from "@/jwtHandler";
import { ref, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ApiService from '@/services/ApiService';

// Obtendo a rota atual
const route = useRoute();
const isAuthenticated = ref(JWTHandler.isCurrentTokenValid());
const isSubscriber = ref(false);
const userData = ref({});

onMounted(async ()=>{
  userData.value = JWTHandler.getUserData();
  console.log(userData.value);

  if(JWTHandler.isCurrentTokenValid()) {
      const isSubscriberResponse = await ApiService.get(`/subscriptions/isSubscriber`);
      isSubscriber.value = isSubscriberResponse.data;
    }
    
})
// Computed properties para verificar a rota atual
const isHometwoRoute = computed(() => route.path === "/home/index-two");
const isHomethreeRoute = computed(() => route.path === "/");
const isHomefourRoute = computed(() => route.path === "/home/index-four");

// Referências para os botões de alternância de modo
const darkModeToggle = ref(null);
const lightModeToggle = ref(null);

// Função para habilitar o modo escuro
function enableDarkMode() {
  document.documentElement.setAttribute("class", "light dark");
  if (darkModeToggle.value) darkModeToggle.value.classList.remove("activate");
  if (lightModeToggle.value) lightModeToggle.value.classList.add("activate");
  localStorage.setItem("darkMode", "enabled");
}

// Função para desabilitar o modo escuro
function disableDarkMode() {
  document.documentElement.setAttribute("class", "light");
  if (lightModeToggle.value) lightModeToggle.value.classList.remove("activate");
  if (darkModeToggle.value) darkModeToggle.value.classList.add("activate");
  localStorage.removeItem("darkMode");
}

// Verificar o modo atual ao carregar a página
onMounted(() => {
  const darkMode = localStorage.getItem("darkMode");
  if (darkMode === "enabled") {
    enableDarkMode();
  } else {
    disableDarkMode();
  }
});
const router = useRouter();
const logout = () => {
  JWTHandler.logout();
  // Redirecionar para a página inicial
  router.push('/').then(() => {
    // Force uma recarga de página para atualizar componentes
    location.reload();
  });

};

const checkoutSubscription = async () => {
  if(!isAuthenticated.value) {
    router.push('/login');
  } else {
    router.push('/pages/checkout-subscription');
  }
}

</script>
