<template>
  <div class="tab-pane fade" id="given_review">
    <!-- Review -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>6 months ago</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          Stephen has shown exceptional understanding and engagement in the
          "Introduction to Economics" course. Your analytical skills and ability
          to apply economic theories to real-world scenarios have been
          impressive. Your active participation in class discussions have also
          contributed positively to the learning environment. Keep up the great
          work!
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Edit</a>
          <a href="javascript:void(0);">Delete</a>
        </div>
      </div>
    </div>
    <!-- /Review -->
    <!-- Review -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>8 months ago</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          Lashawn , I've noticed your keen interest in "Modern Art History" and
          appreciate your efforts to understand complex concepts. However, I
          encourage you to delve deeper into the assigned readings and improve
          your citation skills in your essays. Engaging more critically with the
          material will enhance your analyses and academic writing.
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Edit</a>
          <a href="javascript:void(0);">Delete</a>
        </div>
      </div>
    </div>
    <!-- /Review -->

    <!-- Review -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>9 months ago</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          George, you've made commendable progress in understanding the basics
          of data science. Your project showcased a good grasp of data
          manipulation techniques. However, I've observed challenges in your
          approach to statistical modeling, where a clearer understanding of
          concepts is needed.
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Edit</a>
          <a href="javascript:void(0);">Delete</a>
        </div>
      </div>
    </div>
    <!-- /Review -->

    <!-- Review -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>1 year ago</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          Lisa, while your assignments for "Introduction to Philosophy" show
          basic understanding, I encourage you to engage more deeply with the
          course materials and participate in forum discussions. Sharing your
          thoughts and questions can greatly enhance your comprehension and
          contribute to our class community.
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Edit</a>
          <a href="javascript:void(0);">Delete</a>
        </div>
      </div>
    </div>
    <!-- /Review -->
  </div>
</template>
