<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Courses -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-info">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Meus Cursos</h3>
                <p>Gerencie seus Cursos e suas Atualizações</p>
              </div>
              <div class="checkout-form pb-0">
                <div class="wishlist-tab">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        class="active"
                        data-bs-toggle="tab"
                        data-bs-target="#enroll-courses"
                        >Publicado ({{publishedCourses.length}})</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#active-courses"
                        >Pendente ({{pendingCourses.length}})</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="tab-content">
                  <div class="tab-pane fade show active" id="enroll-courses">
                    <instructor-pending-course :courses="publishedCourses"></instructor-pending-course>
                  </div>
                  <div class="tab-pane fade" id="active-courses">
                    <instructor-pending-course :courses="pendingCourses"></instructor-pending-course>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="dash-pagination">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Page 1 of 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Courses -->
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import ApiService from '@/services/ApiService';
import axiosInstance from "@/axiosConfig";
// Dados para a página
const title = ref("Meus Cursos");
const text = ref("Home");
const text1 = ref("Meus Cursos");

// Lista de cursos resumidos
const courseSummaries = ref([]);

// Função para buscar os cursos resumidos
const fetchCourseSummaries = async () => {
  try {
    const response = await ApiService.get('/courses/me');
    courseSummaries.value = response.data || [];
  } catch (error) {
    console.error("Erro ao buscar os cursos resumidos:", error);
  }
};
const draftCourses = computed(() => {
  return courseSummaries.value?.filter(course => course.status === 'RASCUNHO');
});

const pendingCourses = computed(() => {
  return courseSummaries.value?.filter(course => course.status === 'PENDENTE');
});

const publishedCourses = computed(() => {
  return courseSummaries.value?.filter(course => course.status === 'PUBLICADO');
});
// Carregar os cursos ao montar o componente
onMounted(() => {
  fetchCourseSummaries();
});
</script>
