<template>
  <div
    class="modalStyle modal fade"
    id="usuarioNaoLogadoModal"
    tabindex="-1"
    aria-labelledby="addpaymentMethod"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Usuário não logado</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <i class="fa-regular fa-circle-xmark"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="addpaymethod-form">
            <form action="#">
              <div class="row">
                <div class="col-lg-12">
                  <div class="input-block">
                    <label class="form-control-label">Para continuar você precisa de um cadastro em nossa plataforma.</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <div class="row">
            <button
            type="button" class="btn btn-wish" data-bs-dismiss="modal" @click="login">
                Fazer Login
          </button>
          </div>
          <button
            type="button"
            class="btn btn-wish"
            data-bs-dismiss="modal"
            @click="register"
          >
            Criar uma conta
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="usuarioNaoLogadoModal2">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="page-wrapper-new p-0">
          <div class="content">
            <div class="modal-header border-0 custom-modal-header">
              <div class="page-title">
                <h4>Add New Ticket</h4>
              </div>
              <button
                type="button"
                class="close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="feather-x"></i>
              </button>
            </div>
            <div class="modal-body custom-modal-body">
              <form action="/student-tickets">
                <div class="tickets-add-list">
                  <div class="settings-inner-blk add-course-info p-0">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="input-block">
                          <label class="form-label">Ticket Title</label>
                          <input type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-block">
                          <label class="form-label">Category</label>
                          <vue-select
                            :options="CateTickets"
                            id="catetickets"
                            placeholder="Choose Category"
                          />
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="input-block">
                          <label class="form-label">Priority</label>
                          <vue-select
                            :options="PriorityTickets"
                            id="prioritytickets"
                            placeholder="Choose Priority"
                          />
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="input-block">
                          <label class="form-label">Description</label>
                          <ckeditor
                            :editor="editor"
                            v-model="editorData"
                            :config="editorConfig"
                          ></ckeditor>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="input-block">
                          <label class="form-label">Attachment</label>
                          <div class="file-drop">
                            <div action="#" class="dropzone">
                              <p>Drag & Drop files</p>
                            </div>
                          </div>
                          <div class="accept-drag-file">
                            <p>Accept File Type: doc,docx,jpg,jpeg,png,txt,pdf</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer-btn">
                  <button type="submit" class="btn btn-primary">Submit</button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-bs-dismiss="modal"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { defineProps } from 'vue';
  
const props = defineProps({
  course: {
    type: Object,
    required: true,
  },
  discount: {
    type: Number,
    required: false,
  },
});
// Dados reativos usando `ref`
const editor = ref(ClassicEditor);
const editorData = ref('Description');
const editorConfig = ref({});
const router = useRouter();
const login = () => {
  router.push(
    {
      path:'/login',
      query: { 
        redirect: props.course.isPublic ? `/${props.course.slug}` : `/pages/checkout?course=${props.course.slug}${props.discount ? '&discount=' + props.discount : ''}`,
      }
    });
}
const register = () => {
  router.push({
      path:'/register',
      query: { 
        redirect: props.course.isPublic ? `/${props.course.slug}` : `/pages/checkout?course=${props.course.slug}${props.discount ? '&discount=' + props.discount : ''}`,
      }
    });
}
const YearTick = ref(['Year', 'India', 'America', 'London']);
const TicketSel = ref(['Month', 'Brazil', 'French']);
const CateTickets = ref(['Choose Category', 'Mailing Issue', 'Language Issue', 'Installation Error']);
const PriorityTickets = ref(['Choose Priority', 'High', 'Medium', 'Low']);
</script>
