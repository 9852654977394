<template>
  <div>
  <!-- Favourite Course -->
  <section class="home-three-favourite" v-if="false">
    <div class="container">
      <div class="row">
        <div class="container">
          <div class="home-three-head section-header-title">
            <div class="row align-items-center d-flex justify-content-between">
              <div class="col-lg-8 col-sm-12">
                <h2>Choose favourite Course from top Category</h2>
              </div>
              <div class="col-lg-4 col-sm-12">
                <div class="see-all">
                  <router-link to="/course/course-details"
                    >See all<span class="see-all-icon"
                      ><i class="fas fa-arrow-right"></i></span
                  ></router-link>
                </div>
              </div>
            </div>
          </div>

          <Carousel
            class="home-three-favourite-carousel"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide v-for="course in coursethree" :key="course.name" class="favourite-box">
              <div class="carousel__item favourite-item flex-fill text-start">
                <div class="categories-icon">
                  <img
                    class="img-fluid"
                    :src="require(`@/assets/img/category/${course.icon}`)"
                    :alt="course.name"
                  />
                </div>
                <div class="categories-content course-info">
                  <h3>{{ course.name }}</h3>
                </div>
                <div class="course-instructors">
                  <div class="instructors-info">
                    <p class="me-4">Instructors</p>
                    <ul class="instructors-list">
                      <li>
                        <a
                          href="javascript:;"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="leader 1"
                          ><img src="@/assets/img/profiles/avatar-01.jpg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="leader 2"
                          ><img src="@/assets/img/profiles/avatar-02.jpg" alt="img"
                        /></a>
                      </li>
                      <li>
                        <a
                          href="javascript:;"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title=""
                          data-bs-original-title="leader 3"
                          ><img src="@/assets/img/profiles/avatar-03.jpg" alt="img"
                        /></a>
                      </li>
                      <li class="more-set">
                        <a href="javascript:;">80+</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
      <!-- /Favourite Course -->
    </div>
  </section>
  <!-- Feature Course -->

  <!-- Courses -->
   <div  v-for="category in categories"  :key="category.id" :ref="el => setRef(el, category.id)">
    <section class="home-three-courses" v-if="courses.filter(c => +c.category.id === +category.id).length > 0" >
    <div class="container">
      <div class="favourite-course-sec">
        <div class="row">
          <div class="home-three-head section-header-title" data-aos="fade-up">
            <div class="row align-items-center d-flex justify-content-between">
              <div class="col-lg-12 col-sm-8">
                <h2>{{category.description}}</h2> <span v-if="category.id === 5">Sua ecoteca onde você estiver. Uma biblioteca interativa de exames de imagem e a discussão dos casos com grandes especialistas.</span> 
              </div>
              <div class="col-lg-6 col-sm-4">
                <div class="see-all">
                  <!-- <a href="javascript:void(0);"
                    >Ver todos<span class="see-all-icon"
                      ><i class="fas fa-arrow-right"></i></span
                  ></a> -->
                </div>
              </div>
            </div>
          </div>

          <div class="all-corses-main">
            <div class="tab-content">
              <div class="nav tablist-three" role="tablist">
                <a 
                  class="nav-tab active me-3"
                  data-bs-toggle="tab"
                  :href="'#alltab_'+category.id"
                  role="tab"
                  >Todos</a
                >
                <a 
                  class="nav-tab me-3"
                  data-bs-toggle="tab"
                  :href="'#category_is_public_'+category.id"
                  role="tab"
                  >Acesso Livre</a
                >
                <a 
                  class="nav-tab me-3"
                  data-bs-toggle="tab"
                  :href="'#category_freeToSubscribers_' + category.id"
                  role="tab"
                  >Assinantes</a
                >
                <!-- <a 
                  class="nav-tab me-3"
                  data-bs-toggle="tab"
                  :href="'#category_' + category.id"
                  role="tab"
                  >{{category.description}}</a
                >
                -->
              </div>
              <coursestab-content :isEchoflix="false" v-if="courses.length > 0" :courses="courses.filter(c => +c.category.id === +category.id)" :category_id="category.id"></coursestab-content>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

   </div>
  
  <!-- /Courses -->

  <!-- Call to Action -->
  <section class="home-three-transform">
    <div class="container" data-aos="fade-up">
      <div class="row align-items-center">
        <div class="col-lg-9 col-md-8 col-sm-12">
          <div class="cta-content">
            <h2>Seja assinate e tenha acesso a conteúdos exclusivos</h2>
            <p>
              Como assinante da plataforma você terá acesso a diversas imagens de eco com cardiopatias
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-md-4 col-sm-12">
          <div class="transform-button-three">
            <button @click="checkoutSubscription()" class="btn btn-action"
              >Seja Assinante</button
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
  <!-- Call to Action -->
</template>

<script setup>
import { defineComponent, onMounted, ref } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";
import skillsthree from "@/assets/json/skillsthree.json";
import coursethree from "@/assets/json/coursethree.json";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import ApiService from '@/services/ApiService';
import axiosInstance from "@/axiosConfig";
import { eventBus } from "@/stores/eventBus";
import { useRouter } from "vue-router";
import { JWTHandler } from "@/jwtHandler";

// Define os dados
const skillsthreeData = ref(skillsthree);
const coursethreeData = ref(coursethree);

const settings = {
  itemsToShow: 1,
  snapAlign: "center",
};

const breakpoints = {
  // 700px and up
  700: {
    itemsToShow: 2,
    snapAlign: "center",
  },
  // 1024 and up
  1024: {
    itemsToShow: 5.4,
    snapAlign: "start",
  },
};
const isUsuarioLogado = ref(JWTHandler.isCurrentTokenValid());
const router = useRouter();
const checkoutSubscription = async () => {
  if(!isUsuarioLogado.value) {
    router.push('/login');
  } else {
    router.push('/pages/checkout-subscription');
  }
}
const courses = ref([]);
// Função de montagem
onMounted(async () => {
  AOS.init();
  eventBus.on("scrollTo", scrollMeTo);
  try {
    const response = await ApiService.get('/courses/public');
    courses.value = response.data;
  } catch (error) {
    
  }
});
defineProps({
  categories: {
    type: Array,
    required: true
  }
});

const sectionRefs = ref({});

// Função para armazenar cada referência
const setRef = (el, id) => {
  if (el) sectionRefs.value[id] = el;
};

// Função para rolar até a categoria selecionada
const scrollMeTo = (categoryId) => {
  const element = sectionRefs.value[categoryId];
  if (element) {
    const top = element.offsetTop - 20; // Move 10px acima do elemento
    window.scrollTo({ top, behavior: "smooth" });
  }
};

// Simulação de categorias
</script>