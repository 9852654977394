<template>
  <page-header></page-header>
  <checkoutbreadcrumb></checkoutbreadcrumb>
  <!-- Cart -->
  <Loading
            v-model:active="loadingStore.isLoading"
            :can-cancel="false"
            :is-full-page="true"
          />

  <section class="course-content checkout-widget">
    <div class="container">

      
      <div class="row">
        <div class="col-lg-8">
          <!-- Billing Address -->
          <div class="student-widget">
            <div class="student-widget-group add-course-info">
              <div class="cart-head">
                <h4>Endereço de Cobrança
                </h4>
              </div>
              <div class="checkout-form">
                <form action="#">
                  <div class="row">
                    <div class="col-lg-12">
  <div class="form-group">
    <label class="form-control-label">CPF/CNPJ</label>
    <input
      type="text"
      class="form-control"
      v-model="formData.creditCardHolderInfo.cpfCnpj"
      placeholder="Digite seu CPF ou CNPJ"
    />
  </div>
</div>
<div class="col-lg-12">
  <div class="form-group">
    <label class="form-control-label">CEP</label>
    <input
      type="text"
      class="form-control"
      v-mask="'#####-###'"
      v-model="formData.creditCardHolderInfo.postalCode"
      placeholder="Digite seu CEP"
    />
  </div>
</div>
<div class="col-lg-12">
  <div class="form-group">
    <label class="form-control-label">Número do Endereço</label>
    <input
      type="text"
      class="form-control"
      v-model="formData.creditCardHolderInfo.addressNumber"
      placeholder="Digite o número do endereço"
    />
  </div>
</div>
<div class="col-lg-12">
  <div class="form-group">
    <label class="form-control-label">Complemento do Endereço (Opcional)</label>
    <input
      type="text"
      class="form-control"
      v-model="formData.creditCardHolderInfo.addressComplement"
      placeholder="Digite o complemento do endereço"
    />
  </div>
</div>
<div class="col-lg-6">
  <div class="form-group">
    <label class="form-control-label">Telefone Fixo (Opcional)</label>
    <input
      type="text"
      class="form-control"
      v-model="formData.creditCardHolderInfo.phone"
      placeholder="Digite seu telefone fixo"
    />
  </div>
</div>
<div class="col-lg-6">
  <div class="form-group">
    <label class="form-control-label">Telefone Celular</label>
    <input
      type="text"
      v-mask="'###########'"
      class="form-control"
      v-model="formData.creditCardHolderInfo.mobilePhone"
      placeholder="Digite seu telefone celular"
    />
  </div>
</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Billing Address -->

          <!-- /Payment Method -->
          <div class="student-widget pay-method">
            <div class="student-widget-group add-course-info">
              <div class="cart-head">
                <h4>Pagamento</h4>
              </div>
              <div class="checkout-form">
                <form action="cart">
                  <div class="row">
    <div class="col-lg-12">
      <!-- <div class="wallet-method">

      <label class="radio-inline custom_radio">
        <input 
          type="radio" 
          name="optradio" 
          value="PAYPAL" 
          v-model="formData.billingType" 
        />
        <span class="checkmark"></span> PayPal (Apenas alunos no exterior)
      </label>
      </div>
        <div class="wallet-method">
        <label class="radio-inline custom_radio me-4">
          <input 
            type="radio" 
            name="optradio" 
            value="ASAAS_BOLETO" 
            v-model="formData.billingType" 
             
          />
          <span class="checkmark"></span> Boleto
        </label>
      </div>
        <div class="wallet-method">

        <label class="radio-inline custom_radio me-4">
          <input 
            type="radio" 
            name="optradio" 
            value="ASAAS_PIX" 
            v-model="formData.billingType" 
             
          />
          <span class="checkmark"></span> Pix
        </label>
      </div>
       -->

      <!-- <div class="wallet-method">
        <label class="radio-inline custom_radio me-4">
          <input 
            type="radio" 
            name="optradio" 
            value="ASAAS_CREDIT_CARD" 
            v-model="formData.billingType" 
             
          />
          <span class="checkmark"></span> Cartão de Crédito ou Débito
        </label>
        </div> -->
      <div >
        <div class="form-group">
          <label class="form-control-label">Número do Cartão</label>
          <input
            type="text"
            class="form-control"
            placeholder="XXXX XXXX XXXX XXXX"
            v-model="formData.creditCard.number"
          />
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="form-group">
              <label class="form-label">Mês</label>
              <vue-select
                :options="['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']"
                placeholder="Mês"
                v-model="formData.creditCard.expiryMonth"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label class="form-label">Ano</label>
              <vue-select
                :options="['2023', '2024', '2025', '2026', '2027']"
                placeholder="Ano"
                v-model="formData.creditCard.expiryYear"
              />
            </div>
          </div>
          <div class="col-lg-4">
            <div class="form-group">
              <label class="form-control-label">Código CVV</label>
              <input
                type="text"
                class="form-control"
                placeholder="XXX"
                v-model="formData.creditCard.ccv"
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-control-label">Nome no Cartão</label>
          <input
            type="text"
            class="form-control"
            placeholder="Nome completo"
            v-model="formData.creditCard.holderName"
          />
        </div>
      </div>
      <!-- <div class="form-group ship-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="rememberCard"
        />
        Salvar este cartão
      </div> -->


      <div class="payment-btn" style="padding-top: 20px">
        <button class="btn btn-primary" @click="checkout" type="button" style="width: 100%;">
          Realizar Pagamento
        </button>
      </div>
    </div>
  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Payment Method -->
        </div>
        <div class="col-lg-4 theiaStickySidebar">
          <div class="stickysidebar">
            <div class="student-widget select-plan-group">
              <div class="student-widget-group">
                <div class="plan-header" style="background: green !important">
                  <h4>Assinatura Aluno Caduceus</h4>
                </div>
                <div class="basic-plan">
                  <h3>{{course?.title}}</h3>
                  <p>
                    <!-- For individuals who just need to start with the basic
                    features -->
                  </p>
                  <p><b>{{typePriceSelected}}</b></p>
                  <h2><span>R$</span>{{toCurrency(priceSelected, false)}}</h2>
                </div>
                <div class="benifits-feature">


                  
<!-- •⁠  ⁠DESCONTOS
    * Entre 10% a 50% em todos os cursos oferecidos pelo Caduceus. -->
                  <h3>Conheça os benefícios de ser um assinante Caduceus: </h3>
                  <p>⁠ACESSO GRATUITO A:</p>
                  <ul>
                    <li>
                      <i class="fas fa-circle"></i> Todos os episódios completos Echoflix
                    </li>
                    <li>
                      <i class="fas fa-circle"></i> Todas as aulas do Simpósio 30 anos de Cardiologia Pediátrica
                    </li>
                    <li>
                      <i class="fas fa-circle"></i>  Todas as atualizações
                    </li>
                    <li>
                      <i class="fas fa-circle"></i> Reuniões da Rede de Cardiologia Pediátrica
                    </li>
                  </ul>
                  <p>DESCONTOS:</p>
                  <ul>
                    <li>
                      <i class="fas fa-circle"></i> Entre 10% a 50% em todos os cursos oferecidos pelo Caduceus
                    </li>
                  </ul>
                  <p>PARCERIA:</p>
                  <ul>
                    <li>
                      <i class="fas fa-circle"></i> Sua colaboração ajudar nas atividades beneficentes da ONG Círculo do Coração
                    </li>
                  </ul>
                </div>
                <div class="benifits-feature" v-if="false">
                  <h3>Features</h3>
                  <ul>
                    <li><i class="fas fa-circle"></i> Search term isolation</li>
                    <li><i class="fas fa-circle"></i> Total sales analytics</li>
                    <li><i class="fas fa-circle"></i> Best seller rank</li>
                    <li>
                      <i class="fas fa-circle"></i> Placement optimization
                    </li>
                  </ul>
                </div>
                <div class="plan-change" v-if="false">
                  <router-link to="pricing-plan" class="btn btn-primary"
                    >Change the Plan</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- {{formData}} -->
  <!-- /Cart -->

  <layouts1></layouts1>
</template>
<script setup>
import { useCourse } from '@/components/composables/usecourse';
import { onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { toCurrency } from '@/utils';
import ApiService from '@/services/ApiService';
import axiosInstance, { tratarError } from '@/axiosConfig';
import { useToast } from 'vue-toast-notification';
import { JWTHandler } from '@/jwtHandler';
import Swal from 'sweetalert2';
import { useLoadinStore } from '@/stores/loadingStore';
import { storeToRefs } from 'pinia';
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import { useRouter } from 'vue-router';


const loadingStore = storeToRefs(useLoadinStore());
// Estados e listas estáticas
const state = ref(["Select State", "Brazil", "French"]);
const country = ref(["Brasil", "Fora do Brasil"]);
const month = ref(["Month", "Jun", "Feb", "March"]);
const year = ref(["Year", "2023", "2023", "2023"]);

const { selectedCourse } = useCourse();
const course = ref(selectedCourse);
const lessonsCount = ref(0);
const route = useRoute();
const priceSelected = ref(100.0);
const typePriceSelected = ref('');
const isUsuarioLogado = ref(JWTHandler.isCurrentTokenValid())
const discountId = route.query.discount; // ou route.params.slug, dependendo do seu setup


const formData = ref({
    subscriptionOfferId: 1,
    creditCard: {
      // holderName: '',
      // number: '',
      // expiryMonth: '',
      // expiryYear: '',
      // ccv: '',
      holderName: 'THIAGO RIBEIRO TAVARES',
      number: '4444444444444443',
      expiryMonth: '03',
      expiryYear: '2025',
      ccv: '123',
    },
    creditCardHolderInfo: {
      cpfCnpj: '02829225473',
      postalCode: '52110090',
      addressNumber: '171',
      addressComplement: 'ap 1502',
      phone: '81985900011',
      mobilePhone: '81996627267',

      // cpfCnpj: '',
      // postalCode: '',
      // addressNumber: '',
      // addressComplement: '',
      // phone: '',
      // mobilePhone: '',
    }
});
const router = useRouter();
// Submissão do checkout
const checkout = async () => {
  // const loader = $loading.show();
  try {
      const response = await ApiService.post('/subscriptions', formData.value);
      Swal.fire({
              text: "Assinatura criada com sucesso",
              icon: "success",
              confirmButtonText: "Ok",
            });
      router.push('/caduceus/subscriptions');
      // useToast().success("Assinatura criada com sucesso!")
  } catch (error) {
    tratarError(error);
    console.error('Erro no pagamento:', error);
  } finally {
    // loader.hide()
  }
};

onMounted(async () => {
      // Exemplo de como carregar o curso pelo slug na URL
      // const slug = route.query.course; // ou route.params.slug, dependendo do seu setup
      
      // const response = await ApiService.get(`/courses/${slug}`,  {
      //     headers: {
      //   'Cache-Control': 'no-cache',
      //   'Pragma': 'no-cache'}
      // });

      // course.value = response.data;
      // if(discountId) {
      //   priceSelected.value = course.value.coursePrices.find(c=> c.discountId === +discountId).price
      //   typePriceSelected.value = 'Preço selecionado: ' + course.value.coursePrices.find(c=> c.discountId === +discountId).name
      // } else {
      //   priceSelected.value = course.value.basePrice
      //   typePriceSelected.value = ''

      // }
      // lessonsCount.value = course.value.modules.reduce(
      //   (total, module) => total + module.lessons.length,
      //   0,
      // );
      // Altera o título da página dinamicamente
      if (course.value) {
        document.title = `Checkout - ${course.value.title}`;

        // Alterar as meta tags dinamicamente
        const metaTags = [
          { property: 'og:title', content: course.value.title },
          { property: 'og:description', content: course.value.description },
          { property: 'og:image', content: course.value.coverImage },
          { property: 'og:url', content: window.location.href },
        ];

        metaTags.forEach(({ property, content }) => {
          let metaTag = document.querySelector(`meta[property="${property}"]`);
          if (!metaTag) {
            metaTag = document.createElement('meta');
            metaTag.setAttribute('property', property);
            document.head.appendChild(metaTag);
          }
          metaTag.setAttribute('content', content);
        });
      }
    });
</script>
