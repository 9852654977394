import { ref } from "vue";
import { useLoading } from "vue-loading-overlay";

// Criação de uma instância do Axios
export const toCurrency = (value,  includeCurrencySymbol = true, locale = 'pt-BR', currency = 'BRL') => {
  // console.log(value)
  if (typeof value !== 'number') {
    throw new TypeError('O valor precisa ser um número');
  }

  const options = {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };

  if (!includeCurrencySymbol) {
    // Altera o estilo para decimal caso não queira o símbolo da moeda
    options.style = 'decimal';
    delete options.currency;
  }

  return new Intl.NumberFormat(locale, options).format(value);
}

export const abreviarUUID = (uuid) => {
  if (!uuid || uuid.length < 8) {
      throw new Error('UUID inválido.');
  }
  // Retorna os 4 primeiros caracteres e os 4 últimos, com "..." no meio
  return `${uuid.slice(0, 4)}...${uuid.slice(-4)}`;
}


export const formatarDataAbreviada = (isoDate) => {
  if (!isoDate) {
      throw new Error('Data inválida ou não fornecida.');
  }

  const data = new Date(isoDate);

  if (isNaN(data.getTime())) {
      throw new Error('Formato de data inválido.');
  }

  const dia = String(data.getDate()).padStart(2, '0'); // Dia com 2 dígitos
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês com 2 dígitos (0-indexado)
  const ano = data.getFullYear(); // Ano completo
  const horas = String(data.getHours()).padStart(2, '0'); // Hora com 2 dígitos
  const minutos = String(data.getMinutes()).padStart(2, '0'); // Minutos com 2 dígitos

  return `${dia}/${mes}/${ano} ${horas}:${minutos}`;
}


export const getStatusEnrollment = (status) => {
  switch (status) {
    case 'ACTIVE':
      return 'Ativa'
      case 'PENDING':
        return 'Pendente'
        case 'CANCELLED':
          return 'Cancelada'
        
    default:
      return 'Desconhecido'
      break;
  }
}

export const getStatusPayment = (status) => {

  switch (status) {
    case 'PAID':
      return 'Pago'
      case 'PENDING':
        return 'Pendente'
        case 'FAILED':
          return 'Falhou'
           case 'REFUNDED':
          return 'Reembolsado'
        
    default:
      return 'Desconhecido'
      break;
  }
}