<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Instructor Order History -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Meus Pagamentos</h3>
              </div>
              <div class="checkout-form">
                <!-- Order Tabs -->
                <div class="wishlist-tab order-tab" v-if="false">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        class="active"
                        data-bs-toggle="tab"
                        data-bs-target="#today"
                        >Today</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#month"
                        >Monthly</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#year"
                        >Yearly</a
                      >
                    </li>
                  </ul>
                </div>
                <!-- /Order Tabs -->

                <!-- Tab Content -->
                <div class="tab-content">
                  <!-- Today -->
                  <div class="tab-pane show active" id="today">
                    <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID</th>
          <th>Curso</th>
          <th>Data</th>
          <th>Preco</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="payment in payments" :key="payment.id">
          <td>#{{abreviarUUID(payment.id)}}</td>
          <td>
            {{payment.courseTitle}}
          </td>
          <td>{{formatarDataAbreviada(payment.paymentDate)}}</td>
          <td>{{ toCurrency(payment.amountPaid) }}</td>
          <td>{{payment.status}}</td>
          <td>
            <a :href="payment.receiptUrl" target="_blank" class="action-icon" v-if="payment.receiptUrl"
              ><i class="bx bxs-download"></i
            ></a>
          </td>
        </tr>
        
      </tbody>
    </table>
  </div>
                  </div>
                  <!-- /Today -->

                  <!-- Month -->
                  <!-- <div class="tab-pane fade" id="month">
                    <instructor-month></instructor-month>
                  </div> -->
                  <!-- /Month -->

                  <!-- Yearly -->
                  <!-- <div class="tab-pane fade" id="year">
                    <instructor-year></instructor-year>
                  </div> -->
                  <!-- /Yearly -->
                </div>
                <!-- /Tab Content -->
              </div>
            </div>
          </div>

          <div class="dash-pagination" v-if="false">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Page 1 of 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Instructor Order History -->
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>
<script setup>
import axiosInstance from '@/axiosConfig';
import { abreviarUUID, formatarDataAbreviada, toCurrency } from '@/utils';
import { onMounted, ref } from 'vue';
const title = ref("Meus Pagamentos");
const text = ref("Home");
const text1 = ref("Meus Pagamentos");
const payments = ref([]);
onMounted(async () => {
  try {
    const response = await axiosInstance.get('enrollments/payments');
    payments.value = response.data;
  } catch (error) {
    
  }
});
</script>
